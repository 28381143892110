import './index.scss';

import { Menu, Modal } from '@arco-design/web-react';
import { useNavigate } from '@modern-js/runtime/router';
import { FC } from 'react';
import GlobalStore from '@store/globalStore';
import { useModel } from '@modern-js/runtime/model';
import { UserDept } from '@type/copilot';
import useLocale from '@hooks/useLocale';
import { IconBodyText, IconEditBtn, IconExportBtn, IconInfoCircleBtn } from '@arco-iconbox/react-hkgai-government-icon';

// const { SubMenu } = Menu;
// const MenuItem = Menu.Item;

interface UserDropdownProps {
  data?: UserDept[];
}

const UserDropdown: FC<UserDropdownProps> = () => {
  const navigate = useNavigate();
  const locale = useLocale();

  const [, actions] = useModel(GlobalStore);

  const onClickMenuItem = (key: string) => {
    switch (key) {
      case 'changePassword':
        navigate('/pwd/reset');
        break;
      case 'logout':
        actions.onLogOut(() => navigate('/login', { replace: true }));
        break;
      case 'termOfUse':
        Modal.info({
          icon: null,
          wrapClassName: 'term-of-use-modal',
          title: 'Terms of Use',
          content:
            'The application is provided for informational purposes only and should not be relied upon for making legally binding decisions, with users advised to verify critical information through official channels and adhere to all relevant data privacy and ethical guidelines. The application expressly disclaims any responsibility for inaccuracies or misapplication of the information provided.',
        });
        break;
      case 'fqa':
        window.open('https://hkgpt-application.sg.larksuite.com/docx/UichdAOCOoku1HxuYvsleEgdgYd', '_black');
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Menu onClickMenuItem={onClickMenuItem} mode="pop" className="menu-wrap">
        {/* <Menu.Item key="fqa" className="menu-item-hover">
          <IconQuestionCircle /> FAQ
        </Menu.Item> */}
        <Menu.Item key="changePassword" className="menu-item-hover">
          <IconEditBtn /> {locale.user_dropdown_menu_item_change_password}
        </Menu.Item>
        <Menu.Item key="termOfUse" className="menu-item-hover">
          <IconBodyText /> Terms of Use
        </Menu.Item>
        <Menu.Item key="app_version" className="menu-item-hover" disabled>
          <IconInfoCircleBtn /> {`v ${process.env.APP_VERSION}`}
        </Menu.Item>
        <Menu.Item key="logout" className="menu-item-hover">
          <IconExportBtn /> {locale.user_dropdown_menu_item_log_out}
        </Menu.Item>
      </Menu>
    </>
  );
};

export default UserDropdown;
