export const uploadModalI18n: { [key: string]: any } = {
  'en-US': {
    upload_modal_tab_add_file: 'Uploaded attachments',
    upload_modal_tab_add_links: 'Added links',
    // upload_modal_tab_limit_text: '{fileNumber} file(s) and {linkNumber} link(s) selected(you can select up to {limitNumber} files and {limitNumber} links as reference)',
    upload_modal_tab_limit_text: '{fileNumber} file(s) and  {linkNumber} link(s) selected (Limit: {limitNumber} files, {limitNumber} links)',

    upload_modal_upload_trigger_tips: 'Upload attachment of no more than {fileSize} MB here. Supported formats: {ext}.',
    upload_modal_upload_file_max_limit: 'A maximum number of {fileNumber} attachments may be uploaded',

    upload_modal_upload_file_limit: 'Only {ext} can be uploaded. File size should not exceed {fileSize} MB. ',
    upload_modal_upload_placeholder: 'Click or drag files here to upload',
    upload_modal_tabs_extra_button: 'Confirm',

    upload_modal_add_links_placeholder: 'Enter or paste article link here',
    upload_modal_add_link_max_limit: 'A maximum number of {limitNumber} links may be entered',
  },
  'zh-CN': {
    upload_modal_tab_add_file: '最近上传附件',
    upload_modal_tab_add_links: '最近添加链接',
    upload_modal_tab_limit_text: '已选{fileNumber}个文件，{linkNumber}个链接（上限：{limitNumber}个文件，{limitNumber}个链接）',
    // 已选择x个文件和x个链接（您最多可选2个文件和2个链接作为参考）

    upload_modal_upload_file_max_limit: '最多添加{fileNumber}个文件',

    upload_modal_upload_trigger_tips: '在此上传文件，单个文件最大支持 {fileSize} MB，支持格式：{ext}。',
    upload_modal_upload_file_limit: '仅限 {ext}  格式可以上传，大小不得超过{fileSize}MB。',
    upload_modal_upload_placeholder: '点击或拖拽文件到此处上传',
    upload_modal_tabs_extra_button: '确认',

    upload_modal_add_links_placeholder: '在此处输入或粘贴文章链接',
    upload_modal_add_link_max_limit: '最多可输入{limitNumber}个链接',
  },
  'zh-HK': {
    upload_modal_tab_add_file: '最近上傳附件',
    upload_modal_tab_add_links: '最近添加鏈接',
    upload_modal_tab_limit_text: '已選{fileNumber}個文件，{linkNumber}個鏈接（上限{limitNumber}個文件和{limitNumber}個鏈接）',
    upload_modal_upload_file_limit: '僅限 {ext}  格式可以上傳，大小不得超過 {fileSize}MB。',
    upload_modal_upload_file_max_limit: '最多添加{fileNumber}個文件',
    upload_modal_upload_trigger_tips: '在此上傳文件，單個文件最大支持 {fileSize} MB，支持格式：{ext}。',
    upload_modal_upload_placeholder: '點擊或拖拽文件到此處上傳',
    upload_modal_tabs_extra_button: '確認',
    upload_modal_add_links_placeholder: '在此處輸入或粘貼文章鏈接',
    upload_modal_add_link_max_limit: '最多可輸入{limitNumber}個鏈接',
  },
};
