import { Department, Role } from './userinfo';

export interface InfoReq {}

export interface InfoResp {
  dept?: Department;
  role: Role;
}

// 用户所在部门
export interface UserDept {
  role: Role;
  dept: Department;
  selected: boolean;
}

export interface CopilotParam {
  content?: string; // 用户输入的内容
  replyLanguage?: string; // LanguageType 需要AI回复的语言类型：Traditional Chinese | Chinese | English
  wordNum?: string; // number 需要AI回复的字数
  brainstorm?: string;
  style?: string; // OutputStyle 需要AI回复的风格
  instruction?: string;
  prevText?: string;
  followingText?: string;
  selectedText?: string;
}

export interface SensitveVerifyReq {}
export interface SensitveVerifyResp {
  distinctId: string;
}

export interface InstructionKeyPairs {
  key: string;
  value: string;
}

export interface ResultReport {
  model?: string;
  key?: string;
  parameters?: InstructionKeyPairs[];
  stream?: boolean;
  result?: string;
  generate_id?: string;
  comments?: string[];
}

enum NotificationStatus {
  NOTIFICATION_STATUS_UNKNOWN = 0,
  NOTIFICATION_STATUS_DRAFT = 1,
  NOTIFICATION_STATUS_INUSE = 2,
  NOTIFICATION_STATUS_EXPIRED = 3,
  NOTIFICATION_STATUS_DISCARD = 4,
}

export interface GetGlobalNotificationReq {}

export interface Notification {
  id?: string;
  title?: string;
  contentEn?: string;
  contentZh?: string;
  contentZhHk?: string;
  createTimeSech?: string;
  startTimeSec?: string;
  endTimeSec?: string;
  status?: NotificationStatus;
}

export interface GetGlobalNotificationResp {
  notification: Notification;
}

export enum SidebarType {
  UNKNOWN = 0,
  COPILOT = 1,
  WRITING_FLOW = 2,
  PROOFREADING = 3,
  CHATBOT = 4,
  FEEDBACK = 5,
  HK_CHAT = 99,
  HK_COPILOT = 100,
}

// 定义组件的状态
export enum SidebarStatus {
  ENABLED = 0, // 组件启用
  DISABLED = 1, // 组件禁用
}
export interface Sidebars {
  type: SidebarType;
  status: SidebarStatus;
}

export interface SubscribeRequest {}

export interface SubscribeResponse {
  sidebars: Sidebars[];
}

export interface DeleteConversationReq {
  key: string;
  id: string;
}

export interface DeleteConversationResp {}
