import { createContext } from 'react';
import { TrackEvent, User } from '@hkgai/tracker-sdk';

export const GlobalContext = createContext<{
  lang?: string;
  setLang?: (value: string) => void;
  collectEvent?: (events: TrackEvent[]) => void;
  setUserInfo?: (user: any) => void;
}>({});

export const AuthContext = createContext<{
  user: User;
}>(null!);
