export const pwdI18n = {
  'en-US': {
    pwd_forgot_title: 'Your email address',
    pwd_forgot_input_text_placeholder: 'Email address',
    pwd_forgot_input_text_required: 'Your email address',
    pwd_forgot_input_submit: 'Next',
    pwd_forgot_message_success: `We've just sent you an email to reset your password. Please click on the reset password link to set a new password.`,
    pwd_forgot_status_connection_failed: 'Connection failed. Please retry!',
    pwd_forgot_submit_failed: 'No account found. Please contact the admin.',
    pwd_forgot_validator_email_error: 'Invalid email address. Please check.',
    pwd_forgot_err_send_email_limit: 'A password reset link has been sent to your email. If you have not received it, please try again in five minutes.',

    pwd_reset_title: 'Reset password',
    pwd_reset_input_submit: 'Confirm reset',

    pwd_change_title: 'Change password',
    pwd_change_input_new_pwd_placeholder: '8-15 character password containing at least 1 uppercase and lowercase letter, number, and symbol.',
    pwd_change_input_confirm_pwd_placeholder: 'Please confirm your password.',
    pwd_change_input_verification_placeholder: 'Enter the verification code we sent to your email.',
    pwd_change_input_pwd_error: '8-15 character password containing at least 1 uppercase and lowercase letter, number, and symbol.',
    pwd_change_input_submit: 'Confirm Change',

    pwd_change_password_match_error: 'The passwords do not match. Please try again.',
    pwd_change_password_validator_error: 'The password does not meet the requirements. Please try again.',
    pwd_change_password_success: 'Your password has been reset. Please log in.',
    pwd_change_password_required: 'Password is required',
    pwd_change_input_resend_button: 'Resend',
    pwd_change_input_resend_button_default_value: 'Send',

    pwd_change_status_connection_failed: 'Connection failed. Please retry!',
    pwd_change_status_verification_number_failed: 'Wrong verification code. Please retry!',
    pwd_change_status_verificationnumber_sending: 'A verification code has been sent. Please check.',
    pwd_change_duplication: 'New password cannot be the same as the old one.',
    pwd_change_err_verify_code_sending: 'The user verification code has been sent. Please check.',
    pwd_change_err_verify_code_invalid: 'The verification code is invalid.',
    pwd_change_err_verify_code_limit: 'Due to excessive verification code requests, please try again later.',
  },
  'zh-CN': {
    pwd_forgot_title: '请输入邮箱账号',
    pwd_forgot_input_text_placeholder: '邮箱账号',
    pwd_forgot_input_text_required: '请输入邮箱账号',
    pwd_forgot_input_submit: '下一步',
    pwd_forgot_message_success: '我们已发送重置密码电子邮件，请点击重置密码链接以设置新密码',
    pwd_forgot_status_connection_failed: '服务连接错误，请重试！',
    pwd_forgot_submit_failed: '该账户不存在，请联系管理员',
    pwd_forgot_validator_email_error: '邮箱地址有误，请检查',
    pwd_forgot_err_send_email_limit: '重置密码信息已发送至您的邮箱，如未收到，请于五分钟后重试',

    pwd_reset_title: '重置密码',
    pwd_reset_input_submit: '确认重置',

    pwd_change_title: '修改密碼',
    pwd_change_input_new_pwd_placeholder: '请输入8-15位含大写、小写字母、数字和特殊符号的密码',
    pwd_change_input_confirm_pwd_placeholder: ' 确认新密码',
    pwd_change_input_verification_placeholder: '请输入您的邮箱中收到的验证码',
    pwd_change_input_pwd_error: '请输入8-15位含大写、小写字母、数字和特殊符号的密码',
    pwd_change_input_submit: '确认修改',
    pwd_change_password_match_error: '密码不一致，请重试',
    pwd_change_password_validator_error: '密码不符合要求，请重试',
    pwd_change_password_success: '密码已重置，请重新登陆',
    pwd_change_password_required: '请输入密码',
    pwd_change_input_resend_button: '重新发送',
    pwd_change_input_resend_button_default_value: '发送',

    pwd_change_status_connection_failed: '服务连接错误，请重试！',
    pwd_change_status_verification_number_failed: '验证码错误，请重试！',
    pwd_change_status_verificationnumber_sending: '验证码已发送 请等待接收',
    pwd_change_duplication: '新密码不能与历史密码相同',
    pwd_change_err_verify_code_sending: '用户验证码已发送 请等待接收',
    pwd_change_err_verify_code_invalid: '验证码无效',
    pwd_change_err_verify_code_limit: '用户验证码发送上限，无法发送新验证码',
  },
  'zh-HK': {
    pwd_forgot_title: '請輸入郵箱賬號',
    pwd_forgot_input_text_placeholder: '郵箱賬號',
    pwd_forgot_input_text_required: '請輸入郵箱賬號',
    pwd_forgot_input_submit: '下一步',
    pwd_forgot_message_success: '我們已發送重置密碼電子郵件，請點擊重置密碼鏈接以設置新密碼',
    pwd_forgot_status_connection_failed: '服務連接錯誤，請重試！',
    pwd_forgot_submit_failed: '該賬戶不存在，請聯繫管理員',
    pwd_forgot_validator_email_error: '電郵地址有誤，請檢查',
    pwd_forgot_err_send_email_limit: '重置密碼信息已發送至您的郵箱，如未收到，請於5分鐘後重試',

    pwd_reset_title: '重置密碼',
    pwd_reset_input_submit: '確認重置',

    pwd_change_title: '修改密碼',
    pwd_change_input_new_pwd_placeholder: '請輸入8-15位含大寫、小寫字母、數字和特殊符號的密碼',
    pwd_change_input_confirm_pwd_placeholder: ' 確認新密碼',
    pwd_change_input_verification_placeholder: '請輸入您的郵箱中收到的驗證碼',
    pwd_change_input_pwd_error: '請輸入8-15位含大寫、小寫字母、數字和特殊符號的密碼',
    pwd_change_input_submit: '確認修改',
    pwd_change_password_match_error: '密碼不一致，請重試',
    pwd_change_password_validator_error: '密碼不符合要求，請重試',
    pwd_change_password_success: '密碼已重置，請重新登入',
    pwd_change_password_required: '請輸入密碼',
    pwd_change_input_resend_button: '重新發送',
    pwd_change_input_resend_button_default_value: '發送',

    pwd_change_status_connection_failed: '服務連接錯誤，請重試！',
    pwd_change_status_verification_number_failed: '驗證碼錯誤，請重試！',
    pwd_change_status_verificationnumber_sending: '驗證碼已發送，請等待接收',
    pwd_change_duplication: '新密碼不能與歷史密碼相同',
    pwd_change_err_verify_code_sending: '用戶驗證碼已經發送，請等待接收',
    pwd_change_err_verify_code_invalid: '驗證碼無效',
    pwd_change_err_verify_code_limit: '用戶驗證碼發送已達上限，無法發送新驗證碼',
  },
};
