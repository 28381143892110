export const sidebarI18n: { [key: string]: any } = {
  'en-US': {
    sidebar_ai_writing: 'AI Writing',
    sidebar_ai_proof_reading: 'AI Proofreading',
    sidebar_ai_chat_bot: 'AI Bot',
    sidebar_ai_feedback: 'Feedback',
    sidebar_writing_flow: 'Outline Writing',
  },
  'zh-CN': {
    sidebar_ai_writing: '公文写作',
    sidebar_ai_proof_reading: 'AI 校对',
    sidebar_ai_chat_bot: 'AI 助手',
    sidebar_ai_feedback: '反馈',
    sidebar_writing_flow: '大纲写作',
  },
  'zh-HK': {
    sidebar_ai_writing: '公文寫作',
    sidebar_ai_proof_reading: 'AI 校對',
    sidebar_ai_chat_bot: 'AI 助手',
    sidebar_ai_feedback: '反饋',
    sidebar_writing_flow: '大綱寫作',
  },
};
