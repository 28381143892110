export enum ErrorCode {
  UNKNOWN = 0,

  // 0 - 999 全体服务的通用错误码
  // 0 - 9999 这些错误码如果不在API层做处理，是直接透传给app和web端的

  // HTTP 错误码
  SUCCESS = 200, // 成功
  BAD_REQUEST = 400, // 参数错误
  FORBIDDEN = 403, // 无权限
  NOT_FOUND = 404, // 不存在
  SERVER_ERROR = 500, // 服务端内部错误

  NOT_LOGIN = 401, // 需要登录
  H_ERR_DB_ERROR = 1000,
  H_ERR_NOT_LOGIN = 2000, // 用户未登录
  H_ERR_WRONG_LOGIN = 2001, // 您输入的用户名或者密码错误
  H_ERR_USE_EXIST = 2002, // 您输入的用户名已存在，请更换
  H_ERR_USER_UNACTIVATE = 2003, // 用户未激活
  H_ERR_USER_BLOCKED = 2004, // 用户被禁用
  H_ERR_USER_PASSWORD_STRENGTH = 2005, // 密码强度弱
  H_ERR_USER_PASSWORD_WRONG_LOGIN_LIMIT = 2006, //  账户密码错误阀值锁定
  H_ERR_USER_PASSWORD_DUPLICATION = 2007, // 历史密码重复
  H_ERR_USER_CHANGE_CYCLE = 2008, // 3个月有效期，强制修改密码
  H_ERR_USER_NOT_EXIST = 2009, // 用户不存在

  H_ERR_VERIFY_CODE = 2100, // 用户验证码错误
  H_ERR_VERIFY_CODE_SENDING = 2101, // 用户验证码已发送 请等待接收
  H_ERR_VERIFY_CODE_LIMIT = 2102, // 用户验证码发送上限 禁止发送
  H_ERR_VERIFY_CODE_INVALID = 2103, // 验证码无效
  H_ERR_SEND_EMAIL_LIMIT = 2104, // 邮件已发送

  H_ERR_SYSTEM_ALREADY_INIT = 3001, // 系统已存在
  H_ERR_SYSTEM_NOT_INIT = 3002, // 系统未初始化

  // 用户权限不足
  H_ERR_USER_NO_PERMISSION = 3005, // 用户权限不足
  H_ERR_BATCH_USER_CREATE = 3006,

  H_ERR_DEPT_NAME_EXIST = 4001, // 部门名称已存在

  //   文件解析异常
  H_ERR_FILE_NOT_EXIST = 5000, // 文件不存在
  H_ERR_FILE_PARSER = 5001, // 文件解析异常
}

export const httpCode2Message = new Map([
  [ErrorCode.BAD_REQUEST, '参数错误'],
  [ErrorCode.FORBIDDEN, '无权限'],
  [ErrorCode.NOT_FOUND, '不存在'],
  [ErrorCode.SERVER_ERROR, '服务端内部错误'],
  [ErrorCode.H_ERR_USER_UNACTIVATE, ' 用户未激活'],
  [ErrorCode.H_ERR_USER_BLOCKED, '用户被封禁'],
]);
