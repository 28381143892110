import { model } from '@modern-js/runtime/model';
import { Message } from '@arco-design/web-react';
import { fetchUserLogOut, UserInfo } from '@/api/passport_api';
import { Resource, ResourceType } from '@/components/upload-modal';
import { Sidebars, SidebarStatus } from '@/type/copilot';
import { getSubscribe } from '@/api/copilot_api';

interface Props {
  userInfo: UserInfo;
  sideBar?: Sidebars[];
  resources?: Resource[];
  selectFiles?: Resource[]; // 选中的资源
  selectReferences?: Resource[]; // 用户添加的linkxxxw
}

const GlobalStore = model<Props>('GlobalStore').define({
  state: {
    userInfo: {},
    resources: [],
    uploadFiles: [],
    selectUploadFiles: [],
    sideBar: [],
  },
  actions: {
    setUser(draft, data) {
      draft.userInfo = data;
    },

    // 资源
    setAddResources(draft, payload) {
      draft.resources = payload;
    },

    // 选中的上传资源
    setSelectFiles(draft, payload) {
      draft.selectFiles = payload;
    },

    // 选中的上传资源
    setSelectReferences(draft, payload) {
      draft.selectReferences = payload;
    },

    //  user login out
    onLogOut: {
      pending(draft) {
        console.log(draft);
      },
      fulfilled(draft, data) {
        console.log(draft, data);
        localStorage.clear();
        sessionStorage.clear();
      },
      rejected(draft, error) {
        Message.error(`code: ${error.code}, msg:${error.msg}` || '操作失败，请稍后再试');
        console.log(draft, error);
      },
    },

    //  get Sidebar config
    fetchSideBar: {
      pending(draft) {
        console.log(draft);
      },
      fulfilled(draft, data: Sidebars[]) {
        draft.sideBar = data.map(item => ({ ...item, status: item.status === SidebarStatus.DISABLED ? item.status : SidebarStatus.ENABLED }));
      },
      rejected(draft, error) {
        Message.error(`code: ${error.code}, msg:${error.msg}` || '操作失败，请稍后再试');
        console.log(draft, error);
      },
    },
  },
  computed: {
    fileList: state => {
      return (state?.resources?.length && state.resources.filter(item => item.type === ResourceType.FILE)) || [];
    },
    references: state => {
      return (state?.resources?.length && state.resources.filter(item => item.type === ResourceType.ATTACHMENT)) || [];
    },
  },
  effects: {
    async onLogOut(callbacks?: VoidFunction) {
      const data = await fetchUserLogOut({});
      callbacks?.();
      return data;
    },

    async fetchSideBar() {
      const { sidebars = [] } = await getSubscribe({});
      return sidebars;
    },
  },
});

export default GlobalStore;
