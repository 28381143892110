export const exitModalI18n: { [key: string]: any } = {
  'en-US': {
    exit_modal_confirm_title: 'Start New Writing？',
    exit_modal_confirm_content: 'Are you sure to start your new writing? All content will not be saved',

    exit_modal_password_expired_content: 'Password expired, please reset',
    exit_modal_password_expired_button: 'Reset Immediately',
  },
  'zh-CN': {
    exit_modal_confirm_title: '确认开始新的写作？',
    exit_modal_confirm_content: '是否确认开始新的写作？所有内容将不会被保存',

    exit_modal_password_expired_content: '安全密码已过期，请重置',
    exit_modal_password_expired_button: '立即重置',
  },
  'zh-HK': {
    exit_modal_confirm_title: '確認開始新的寫作？',
    exit_modal_confirm_content: '是否確認開始新的寫作？所有內容將不會被保存',

    exit_modal_password_expired_content: '安全密碼已過期，請重置',
    exit_modal_password_expired_button: '立即重設',
  },
};
