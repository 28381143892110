export const feedbackI18n: { [key: string]: any } = {
  'en-US': {
    feedback_title: 'Product Feedback',
    feedback_sub_title: 'Your suggestions will help us improve. Thank you for supporting HKCopilot!',
    feedback_module_name_one: '1. We welcome your feedback (Including but not limited to bug reports, product feature suggestions, etc.)',
    feedback_module_name_tow: '2. Relevant feedback images (You are welcome to submit screenshots of bugs or other supporting feedback) ',
    feedback_textarea_placeholder: 'Please enter',
    feedback_input_upload_placeholder: 'Click or drag files here to upload',
    feedback_input_upload_file_type_limit: 'Only {ext} can be uploaded. File size should not exceed 30MB.',
    feedback_input_upload_file_size_limit: 'The upload size limit for feedback input is {fieSize}',

    feedback_form_submit: 'Submit',
    feedback_submitted_message: 'Feedback submitted successfully',
  },
  'zh-CN': {
    feedback_title: '产品反馈',
    feedback_sub_title: '您的建议会让我们做的更好，感谢对HKCopilot的支持！',
    feedback_module_name_one: '1、欢迎您提交反馈（包括但不限于bug反馈、产品功能建议等）',
    feedback_module_name_tow: '2、相关反馈配图（欢迎您提交bug截图等辅助反馈的截图）',
    feedback_textarea_placeholder: '请输入',
    feedback_input_upload_placeholder: '点击或拖拽文件到此处上传',
    feedback_input_upload_file_type_limit: '仅限 {ext}  格式可以上传，大小不得超过30MB。',
    feedback_input_upload_file_size_limit: '反馈输入的上传文件大小限制为 {fieSize}',

    feedback_form_submit: '提交',
    feedback_submitted_message: '反馈已经成功提交',
  },
  'zh-HK': {
    feedback_title: '產品反饋',
    feedback_sub_title: '您的建議會讓我們做得更好，感謝對HKCopilot的支持！',
    feedback_module_name_one: '1、歡迎您提交反饋（包括但不限於bug反饋、產品功能建議等）',
    feedback_module_name_tow: '2、相關反饋配圖（歡迎您提交bug截圖等輔助反饋的截圖） ',
    feedback_textarea_placeholder: '請輸入',
    feedback_input_upload_placeholder: '點擊或拖拽文件到此處上傳',
    feedback_form_submit: '提交',
    feedback_submitted_message: '反馈已經成功提交',
    feedback_input_upload_file_type_limit: '僅限 {ext}  格式可以上傳，大小不得超過30MB。',
    feedback_input_upload_file_size_limit: '反饋輸入的上傳文件大小限制為 {fieSize}',
  },
};
