export const userDropdownI18n: { [key: string]: any } = {
  'en-US': {
    user_dropdown_menu_item_switch_account: 'Switch Account',
    user_dropdown_menu_item_change_password: 'Reset password',
    user_dropdown_menu_item_log_out: 'Log Out',
  },
  'zh-CN': {
    user_dropdown_menu_item_switch_account: '切换账户',
    user_dropdown_menu_item_change_password: '重置密码',
    user_dropdown_menu_item_log_out: '退出',
  },
  'zh-HK': {
    user_dropdown_menu_item_switch_account: '切換帳户',
    user_dropdown_menu_item_change_password: '重置密碼',
    user_dropdown_menu_item_log_out: '退出',
  },
};
