export const aiBotI18n = {
  'zh-CN': {
    ai_bot_slogan: '您好呀！今天我能为您做些什么？',
    ai_bot_head_clear_button: '清除',
    ai_bot_tips_close_button: '关闭',
    ai_bot_action_summary: '摘要总结',
    ai_bot_head_title: 'AI助手',
    ai_bot_footer_input_placeholder: '万事问AI',
    ai_bot_confirm_modal_title: '确认清除',
    ai_bot_confirm_modal_content: '点击确认后，将清空当前对话内容',
    ai_bot_confirm_modal_button_confirm: '确认',
    ai_bot_confirm_modal_button_cancel: '取消',
    ai_bot_action_button_copy: '复制',
    ai_bot_action_button_feedback: '反馈',
    ai_bot_action_button_restart: '重新生成',
    ai_bot_action_button_repeat: '重试',
    ai_bot_summary_prompt: '對輸入文本進行摘要總結，摘要需要明顯比輸入短，且完整、準確地概括了輸入的關鍵內容。',
    ai_bot_copy_success_toast: '内容已复制！',
    ai_bot_feedback_tag_1: '令人不适/不安全',
    ai_bot_feedback_tag_2: '与事实不符',
    ai_bot_feedback_tag_3: '语言有误',
    ai_bot_feedback_tag_4: '其他',
    ai_bot_feedback_placeholder: '提供更详细的反馈意见',
    ai_bot_action_feedback_submit: '提交',
    ai_bot_action_quote: '追问',
    ai_bot_action_quote_all: '引用对话',
    ai_bot_feedback_title: '您的反馈能帮助我们改进产品：',
    ai_bot_feedback_succ_msg: '感谢您提供的反馈！',
  },
  'zh-HK': {
    ai_bot_slogan: '您好呀！今日我可以幫您做啲咩吖?',
    ai_bot_head_clear_button: '清除',
    ai_bot_tips_close_button: '關閉',
    ai_bot_action_summary: '摘要總結',

    ai_bot_head_title: 'AI助手',
    ai_bot_footer_input_placeholder: '諮詢AI',
    ai_bot_confirm_modal_title: '確認清除',
    ai_bot_confirm_modal_content: '點擊確認之後，系統將會清空是次對話記錄',
    ai_bot_confirm_modal_button_confirm: '確認',
    ai_bot_confirm_modal_button_cancel: '取消',
    ai_bot_action_button_copy: '複製',
    ai_bot_action_button_feedback: '反饋',
    ai_bot_action_button_restart: '重新生成',
    ai_bot_action_button_retry: '重试',
    ai_bot_summary_prompt: '对输入文本进行摘要总结，摘要需要明显比输入短，且完整、准确的概括了输入的关键内容。',
    ai_bot_copy_success_toast: '內容已複製！',
    ai_bot_feedback_tag_1: '令人不適/不安全',
    ai_bot_feedback_tag_2: '與事實不符',
    ai_bot_feedback_tag_3: '語言有誤',
    ai_bot_feedback_tag_4: '其他',
    ai_bot_feedback_placeholder: '提供更詳細的反饋意見',
    ai_bot_action_feedback_submit: '提交',
    ai_bot_action_quote: '追問',
    ai_bot_action_quote_all: '引用對話',
    ai_bot_feedback_title: '您的反饋能幫助我們改進產品：',
    ai_bot_feedback_succ_msg: '感謝您提供的反饋！',
  },
  'en-US': {
    ai_bot_slogan: 'Greetings! How may I assist you today?',
    ai_bot_head_clear_button: 'Clear',
    ai_bot_tips_close_button: 'Close',
    ai_bot_action_summary: 'Summary',
    ai_bot_head_title: 'AI Bot',
    // ai_bot_footer_input_placeholder: 'Consult the AI on any matter, or select a specific operation.',
    ai_bot_footer_input_placeholder: 'Ask AI anything',
    ai_bot_confirm_modal_title: 'Confirm',
    ai_bot_confirm_modal_content: 'Clicking "Confirm" will clear the current conversation content',
    ai_bot_confirm_modal_button_confirm: 'Confirm',
    ai_bot_confirm_modal_button_cancel: 'Cancel',
    ai_bot_action_button_copy: 'Copy',
    ai_bot_action_button_feedback: 'Feedback',
    ai_bot_action_button_restart: 'Regenerate',
    ai_bot_action_button_retry: 'Retry',
    ai_bot_summary_prompt: 'Summarize the input text. The summary needs to be significantly shorter than the input and accurately and completely summarize the key content.',
    ai_bot_copy_success_toast: 'Content has been copied!',
    ai_bot_feedback_tag_1: 'Offensive/Unsafe',
    ai_bot_feedback_tag_2: 'Not factually correct',
    ai_bot_feedback_tag_3: 'Wrong language',
    ai_bot_feedback_tag_4: 'Other',
    ai_bot_feedback_placeholder: 'Provide additional feedback',
    ai_bot_action_feedback_submit: 'Submit',
    ai_bot_action_quote: 'Ask more',
    ai_bot_action_quote_all: 'Quote reply',
    ai_bot_feedback_title: 'Your feedback helps us improve our product:',
    ai_bot_feedback_succ_msg: 'Thanks for your feedback!',
  },
};
