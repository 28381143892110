import { Navigate, useLocation, useNavigate } from '@modern-js/runtime/router';
import { useContext, useEffect, useState } from 'react';
import { Dropdown, Avatar } from '@arco-design/web-react';

import { useMemoizedFn } from 'ahooks';
import { fetchUserInfo } from '@api/copilot_api';
import { GlobalContext } from './context';
import { AUTH_STATUS_KEY, AuthorizationStatus } from '@/config/constant';
import UserDropdown from '@/components/user-dropdown';
import useUrlParams from '@/hooks/useUrlParams';

export const UserAuthProvider = ({ children }: { children: JSX.Element }) => {
  const { resetToken = '' } = useUrlParams();
  const navigate = useNavigate();
  const { setUserInfo } = useContext(GlobalContext);

  useEffect(() => {
    if (!(window.location.href.indexOf('/pwd/reset') >= 0) && !resetToken) {
      getUser();
    }
  }, []);

  const getUser = useMemoizedFn(async () => {
    try {
      const { userInfo = {} } = await fetchUserInfo({});
      setUserInfo?.(userInfo);
      localStorage.setItem('USER_ID', '');
      localStorage.setItem('USER_ID', userInfo.accountId ?? '');
      sessionStorage.setItem(AUTH_STATUS_KEY, AuthorizationStatus.Authenticated);
      const isLoginPage = window.location.href.includes('/login');
      const isLoggedIn = userInfo?.accountId;
      if (isLoginPage && isLoggedIn) {
        navigate('/', { replace: true });
      }
    } catch (error: any) {}
  });

  return children;
};

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const hasAuth = sessionStorage.getItem(AUTH_STATUS_KEY);

  if (!hasAuth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export const AuthUserInfo = () => {
  const { user = {} } = useContext(GlobalContext);

  const [visible, setVisible] = useState(false);

  return (
    <>
      {user.accountId ? (
        <Dropdown
          droplist={<UserDropdown />}
          popupVisible={visible}
          onVisibleChange={visible => {
            setVisible(visible);
          }}
          position="br"
          trigger="click"
        >
          <div className="flex items-center cursor-pointer select-none text-base ">
            <Avatar autoFixFontSize style={{ backgroundColor: '#6585fa' }}>
              {user?.screenName?.substring(0, 1)}
            </Avatar>
          </div>
        </Dropdown>
      ) : (
        <></>
      )}
    </>
  );
};
