export const writeI18n = {
  'en-US': {
    'writing.wordCount': 'Word Count',
    'writing.download.text': 'Download',
    'writing.clear.button': 'Clear',

    'writing.confirm.modal.title': 'Confirm delete',
    'writing.confirm_modal.content': 'Clicking "Confirm" will delete all existing written content',
    'writing.confirm_modal.button.confirm': 'Confirm',
    'writing.confirm.modal.button.cancel': 'Cancel',

    'aiButton.text': 'Ask AI',
    'ai.writing.use.prompt': 'Get started with these prompts',

    'inputBox.settings.styles.unspecific': 'Unspecific',
    'inputBox.settings.styles.officialletter': 'Official Letter',
    'inputBox.settings.styles.notification': 'Notification',
    'inputBox.settings.styles.notes': 'Notes',
    'inputBox.settings.styles.research': 'Research paper',
    'inputBox.settings.styles.essay': 'Essay',
    'inputBox.settings.styles.email': 'Email',
    'inputBox.settings.language.text': 'Language',
    'inputBox.settings.wordNum.text': 'Length',

    'generatingBox.loading.thinking': 'AI Thinking',
    'generatingBox.loading.writing': 'AI Writing',
    'generatingBox.stop.generate': 'Stop Generating',

    'ai.writing.type.expansion': 'Expand',
    'ai.writing.type.continuation': 'Continue',
    'ai.writing.type.translate': 'Translate',
    'ai.writing.type.rewrite': 'Rewrite',
    'ai.writing.type.copy': 'Copy',
    'ai.writing.type.short': 'Make shorter',
    'ai.writing.type.summarize': 'Summarise',
    'ai.writing.prompt.button': 'Confirm',
    'ai.writing.prompt.input.placeholder': 'Tell me about your new idea...',

    'ai.writing.generate.button.use': 'Yes, use it',
    'ai.writing.generate.button.replace': 'Replace original text',
    'ai.writing.generate.tooltip.restart': 'Regenerate',
    'ai.writing.generate.tooltip.deprecated': 'Discard',
    'ai.writing.generate.button.report': 'Feedback',
    'ai.writing.generate.count': 'Generated word count',
    'ai.writing.generate.warning': 'The content above is generated by AI based on user prompt. Please use it for your own reference only.',
    'ai.writing.generate.prompt': 'Prompt',

    'ai.writing.selection.count': 'Selected word count',

    'ai.writing.generate.feedback.modal.title': 'Kindly evaluate the generated output to assist us in enhancing the application',
    'ai.writing.generate.feedback.modal.lable.one': '1. How would you rate the accuracy of the output?',
    'ai.writing.generate.feedback.modal.lable.two': '2. How relevant and useful was the output for your purpose?',
    'ai.writing.generate.feedback.modal.lable.three': '3. Overall, how satisfied are you with the output?',
    'ai.writing.generate.feedback.modal.lable.errtip': 'Your feedback is appreciated',
    'ai.writing.generate.feedback.modal.confirm': 'Confirm',
    'ai.writing.generate.feedback.success': 'Feedback submitted successfully',
    'ai.writing.generate.feedback.fail': 'Feedback failed to submit',

    'ai.writing.title.placeholder': 'Hi, please input your title here...',

    'inputBox.textarea.placeholder': "What's the topic of your writing? Share your thoughts here...",
    'slate.editor.placeholder': `Welcome to HK Copilot, start your writing journey!\nPaste the plain-text content you need to edit here or click "AI Writing" to generate what you want...`,

    'report.modal.header': 'Feedback',
    'report.modal.header.item.political_sensitivity': 'Political Sensitivity',
    'report.modal.header.item.illegal_activities': 'Illegal Activities',
    'report.modal.header.item.obscene_content': 'Obscene Content',
    'report.modal.header.item.inducing_suicide': 'Inducing Suicide',
    'report.modal.header.item.personal_safety': 'Endangering Personal Safety',
    'report.modal.header.item.other': 'Other',
    'report.modal.content.placeholder': 'Enter the feedback content',
    'report.modal.button.text': 'Submit',

    'privacy.modal.report.title': 'Privacy Policy',
    'privacy.modal.report.content':
      'Thank you for using our feedback function! To better handle your feedback, we need to collect and analyse the instructions, files, and generated content you provide.<br />By clicking "Confirm" you agree to and understand the following terms:<br />1. These data will only be used to analyse and improve our services, as well as to address the issues you have reported.<br />2. We commit to strictly protecting your privacy. The collected data will not be used for any other purpose or shared with third parties unless required by law.<br />3. We will take reasonable technical and managerial measures to protect the data you provide from unauthorised access, disclosure, alteration, or destruction.<br /><span style="color:#999;font-size:12px;">If you do not agree, please click "Cancel."</span>',
    'privacy.modal.report.submit': 'Confirm',
    'privacy.modal.report.cancel': 'Cancel',
    'privacy.modal.upload.content':
      'The file you are about to upload will be parsed. The parsing process may involve extracting and processing the contents of the file. Please ensure that the file you upload does not contain any sensitive or confidential information.<br/><br/>By clicking "Confirm Upload," you agree to and understand the following terms:<br/>1. The uploaded file will be parsed and processed.<br/>2. During the parsing process, the contents of the file may be analysed and stored.<br/>3. We commit to protecting your privacy and taking measures to ensure data security.<br/><br/><span style="color:#999;font-size:12px;">If you do not agree, please click "Cancel" to terminate the upload process.</span>',
    'privacy.modal.upload.submit': 'Confirm Upload',
    'privacy.modal.upload.cancel': 'Cancel',
    'confirm.modal.quit.title': 'Are you sure you want to exit?',
    'confirm.modal.quit.content': 'Exit will clear all entered contents. Are you sure you want to exit?',
    'confirm.modal.quit.submit': 'Sure',
    'confirm.modal.addlink.title': 'Add link',
    'confirm.modal.addlink.error': 'Link Error',
    'confirm.modal.addlink.error.duplicate': 'Duplicate link',
    'confirm.modal.addlink.placeholder': 'Please enter or paste the article link here',
    'inputbox.ddl.upload.file': 'Upload file',
    'inputbox.ddl.insert.Link': 'Add link',
    'inputBox.settings.input.placeholder': 'Customise',
    'handle.serve.error.message': 'Oops, the server is not working properly. Please try again.',

    'copy.success.toast': 'Content has been copied!',

    'ai.writing.input.label.user.instruction': 'User prompt',
    'ai.writing.input.label.user.instruction.tooltip': 'Press Shift + Enter to start a new line',
    'ai.writing.input.label.reference': 'Reference',
    'ai.writing.input.placeholder.reference': 'Paste the article/content',
    'ai.writing.input.enable_network_search': 'Online Search',

    'ai.writing.type.customize': 'Customise',
    'ai.writing.type.customize.rewrite': 'Rewrite with user prompt',
    'ai.writing.type.customize.continue': 'Continue writing with user prompt',

    'ai.writing.type.entry': 'Select editing style',

    'ai.writing.translate.simplified': 'Simplified Chinese',
    'ai.writing.translate.english': 'English',
    'ai.writing.translate.traditional': 'Traditional Chinese',
  },
  'zh-CN': {
    'writing.wordCount': '总字数',
    'writing.download.text': '稿件下载',
    'writing.clear.button': '清除',
    'writing.confirm.modal.title': '确认清除',
    'writing.confirm_modal.content': '点击确认后，将清空写作中的所有内容',
    'writing.confirm_modal.button.confirm': '确认',
    'writing.confirm.modal.button.cancel': '取消',

    'aiButton.text': '问问AI',
    'ai.writing.use.prompt': '试试这些问题',

    'inputBox.settings.styles.unspecific': '默认',
    'inputBox.settings.styles.officialletter': '公函',
    'inputBox.settings.styles.notification': '通知',
    'inputBox.settings.styles.notes': '笔记',
    'inputBox.settings.styles.research': '研究论文',
    'inputBox.settings.styles.essay': '论文',
    'inputBox.settings.styles.email': '邮件',
    'inputBox.settings.language.text': '语言',
    'inputBox.settings.wordNum.text': '字数',
    'inputBox.settings.input.placeholder': '自定义',

    'generatingBox.loading.thinking': 'AI 正在思考中',
    'generatingBox.loading.writing': 'AI 正在写作中',
    'generatingBox.stop.generate': '停止生成',

    'ai.writing.type.expansion': '扩写',
    'ai.writing.type.continuation': '续写',
    'ai.writing.type.translate': '翻译',
    'ai.writing.type.rewrite': '改写',
    'ai.writing.type.copy': '复制',
    'ai.writing.type.short': '简写',
    'ai.writing.type.summarize': '总结',
    'ai.writing.prompt.button': '确认',
    'ai.writing.prompt.input.placeholder': '告诉我您的新想法...',

    'ai.writing.generate.button.use': '确认',
    'ai.writing.generate.button.replace': '替换原文',
    'ai.writing.generate.tooltip.restart': '重新生成',
    'ai.writing.generate.tooltip.deprecated': '删除',
    'ai.writing.generate.button.report': '反馈',
    'ai.writing.generate.count': '生成字数',
    'ai.writing.generate.warning': '以上内容由AI基于用户指令生成, 请谨慎参考和使用',
    'ai.writing.generate.prompt': '生成提示',

    'ai.writing.generate.feedback.modal.title': '请对生成内容进行评估，以协助我们提升应用程序。',
    'ai.writing.generate.feedback.modal.lable.one': '1. 您如何评价生成内容的准确性？',
    'ai.writing.generate.feedback.modal.lable.two': '2. 生成内容对于您的用途有多大相关性和实用性？',
    'ai.writing.generate.feedback.modal.lable.three': '3. 从整体来看，您对生成内容的满意度如何？',
    'ai.writing.generate.feedback.modal.lable.errtip': '请留下您的宝贵意见',
    'ai.writing.generate.feedback.modal.confirm': '确认',
    'ai.writing.generate.feedback.success': '反馈成功',
    'ai.writing.generate.feedback.fail': '反馈失败',

    'ai.writing.selection.count': '选中字数',

    'ai.writing.title.placeholder': '请在这里输入标题...',
    'inputBox.textarea.placeholder': '您的文章主题是什么？告诉我您的想法...',
    'slate.editor.placeholder': `欢迎使用 HK Copilot 写作助手，快来开始你的写作之旅吧！\n在这里粘贴你需要编辑的文本内容或者点击“公文写作”生成你想要的內容…`,

    'report.modal.header': '反馈',
    'report.modal.header.item.political_sensitivity': '政治敏感',
    'report.modal.header.item.illegal_activities': '违法犯罪',
    'report.modal.header.item.obscene_content': '淫秽内容',
    'report.modal.header.item.inducing_suicide': '诱导自杀',
    'report.modal.header.item.personal_safety': '危害人身安全',
    'report.modal.header.item.other': '其它',
    'report.modal.content.placeholder': '输入反馈内容',
    'report.modal.button.text': '提交',

    'privacy.modal.report.title': '隐私保护协议',
    'privacy.modal.report.content':
      '感谢您使用我们的举报功能！为了更好地处理您的举报，我们需要收集和分析您所输入的指令、文件和生成内容。<br />点击“确认举报”表示您同意并理解以下条款：<br />1. 这些数据将仅用于分析和改进我们的服务，以及解决您所举报的问题。<br />2. 我们承诺严格保护您的隐私，收集的数据不会用于任何其他目的，也不会与第三方共享，除非法律要求。<br />3. 我们会采取合理的技术和管理措施来保护您提供的数据，防止未经授权的访问、披露、篡改或毁坏。<br /><span style="color:#999;font-size:12px;">如果您不同意，请点击“取消”。</span>',
    'privacy.modal.report.submit': '确认',
    'privacy.modal.report.cancel': '取消',
    'privacy.modal.upload.content':
      '您即将上传的文件将会被解析，解析过程可能涉及文件内容的提取和处理。请确保您上传的文件不包含任何敏感或机密信息。<br/><br/>点击“确认上传”表示您同意并理解以下条款：<br/>1. 上传的文件将被解析和处理。<br/>2. 解析过程中可能会对文件内容进行分析和存储。<br/>3. 我们承诺保护您的隐私，并采取措施确保数据的安全。<br/><br/><span style="color:#999;font-size:12px;">如果您不同意，请点击“取消”以终止上传过程。</span>',
    'privacy.modal.upload.submit': '确认上传',
    'privacy.modal.upload.cancel': '取消',
    'confirm.modal.quit.title': '确认退出吗？',
    'confirm.modal.quit.content': '退出会清空所有已输入内容，是否确认退出？',
    'confirm.modal.quit.submit': '确认',
    'confirm.modal.addlink.title': '添加链接',
    'confirm.modal.addlink.error': '链接错误',
    'confirm.modal.addlink.error.duplicate': '链接重复',
    'confirm.modal.addlink.placeholder': '请在此处输入或粘贴文章链接',

    'inputbox.ddl.upload.file': '上传文件',
    'inputbox.ddl.insert.Link': '添加链接',
    'handle.serve.error.message': '嘿嘿，服务器开小差了，请稍后再试～',

    'copy.success.toast': '内容已复制！',

    'ai.writing.input.label.user.instruction': '用户指令',
    'ai.writing.input.label.user.instruction.tooltip': '按 Shift + Enter 键以开始新行',
    'ai.writing.input.label.reference': '参考内容',
    'ai.writing.input.placeholder.reference': '粘贴文章、内容',
    'ai.writing.input.enable_network_search': '联网搜索',

    'ai.writing.type.customize': '自定义',
    'ai.writing.type.customize.rewrite': '自定义改写',
    'ai.writing.type.customize.continue': '自定义续写',

    'ai.writing.type.entry': '选择编辑方式',

    'ai.writing.translate.simplified': '简体',
    'ai.writing.translate.english': '英语',
    'ai.writing.translate.traditional': '繁体',
  },
  'zh-HK': {
    'writing.wordCount': '總字數',
    'writing.download.text': '稿件下載',
    'writing.clear.button': '清除',
    'writing.confirm.modal.title': '確認清除',
    'writing.confirm_modal.content': '點擊確認後，將清空寫作中的所有內容',
    'writing.confirm_modal.button.confirm': '確認',
    'writing.confirm.modal.button.cancel': '取消',

    'aiButton.text': '問問AI',
    'ai.writing.use.prompt': '试试这些问题',

    'inputBox.settings.styles.unspecific': '默認',
    'inputBox.settings.styles.officialletter': '公函',
    'inputBox.settings.styles.notification': '通知',
    'inputBox.settings.styles.notes': '筆記',
    'inputBox.settings.styles.research': '研究論文',
    'inputBox.settings.styles.essay': '論文',
    'inputBox.settings.styles.email': '郵件',
    'inputBox.settings.language.text': '语言',
    'inputBox.settings.wordNum.text': '字数',
    'inputBox.settings.input.placeholder': '自定義',

    'generatingBox.loading.thinking': 'AI 正在思考中',
    'generatingBox.loading.writing': 'AI 正在寫作中',
    'generatingBox.stop.generate': '停止生成',

    'ai.writing.type.expansion': '擴寫',
    'ai.writing.type.continuation': '續寫',
    'ai.writing.type.translate': '翻譯',
    'ai.writing.type.rewrite': '改寫',
    'ai.writing.type.copy': '複製',
    'ai.writing.type.short': '簡寫',
    'ai.writing.type.summarize': '總結',
    'ai.writing.prompt.button': '確認',
    'ai.writing.prompt.input.placeholder': '告訴我您的新想法...',

    'ai.writing.generate.button.use': '確認',
    'ai.writing.generate.button.replace': '替换原文',
    'ai.writing.generate.tooltip.restart': '重新生成',
    'ai.writing.generate.tooltip.deprecated': '删除',
    'ai.writing.generate.button.report': '反饋',
    'ai.writing.generate.count': '生成字數',
    'ai.writing.selection.count': '選中字數',
    'ai.writing.generate.warning': '以上內容由AI基於用戶指令生成, 請謹慎參考和使用',
    'ai.writing.generate.prompt': '生成提示',

    'ai.writing.generate.feedback.modal.title': '請對生成內容進行評估，以協助我們提升應用程式。',
    'ai.writing.generate.feedback.modal.lable.one': '1. 您如何評價生成內容的準確性？',
    'ai.writing.generate.feedback.modal.lable.two': '2. 生成內容對於您的用途有多大相關性和實用性？',
    'ai.writing.generate.feedback.modal.lable.three': '3. 從整體來看，您對生成內容的滿意度如何？',
    'ai.writing.generate.feedback.modal.lable.errtip': '請留下您的寶貴意見',
    'ai.writing.generate.feedback.modal.confirm': '確認',
    'ai.writing.generate.feedback.success': '反饋成功',
    'ai.writing.generate.feedback.fail': '反饋失敗',

    'ai.writing.title.placeholder': '請在這裏輸入標題...',
    'inputBox.textarea.placeholder': '您的文章主題是什麼？告訴我您的想法...',
    'slate.editor.placeholder': `歡迎使用HK Copilot寫作助手，快來開始你的寫作之旅吧！\n在這裏粘貼你需要編輯的文本內容或者點擊“公文寫作”生成你想要的內容...`,

    'report.modal.header': '反饋',
    'report.modal.header.item.political_sensitivity': '政治敏感',
    'report.modal.header.item.illegal_activities': '違法犯罪',
    'report.modal.header.item.obscene_content': '淫穢內容',
    'report.modal.header.item.inducing_suicide': '誘導自殺',
    'report.modal.header.item.personal_safety': '危害人身安全',
    'report.modal.header.item.other': '其它',
    'report.modal.content.placeholder': '輸入反饋內容',
    'report.modal.button.text': '提交',

    'privacy.modal.report.title': '隱私保護協議',
    'privacy.modal.report.content':
      '感謝您使用我們的反饋功能！為了更好地處理您的反饋，我們需要收集和分析您所輸入的指令、文件和生成內容。<br />點擊「確認提交」表示您同意並理解以下條款：<br />1. 這些數據將僅用於分析和改進我們的服務，以及解決您所反饋的問題。<br />2. 我們承諾嚴格保護您的隱私，收集的數據不會用於任何其他目的，也不會與第三方共享，除非法律要求。<br />3. 我們會採取合理的技術和管理措施來保護您提供的數據，防止未經授權的訪問、披露、篡改或毀壞。<br /><span style="color:#999;font-size:12px;">如果您不同意，請點擊「取消」。</span>',
    'privacy.modal.report.submit': '確認',
    'privacy.modal.report.cancel': '取消',
    'privacy.modal.upload.content':
      '您即將上傳的文件將會被解析，解析過程可能涉及文件內容的提取和處理。請確保您上傳的文件不包含任何敏感或機密信息。<br/><br/>點擊“確認上傳”表示您同意並理解以下條款：<br/>1. 上傳的文件將被解析和處理。<br/>2. 解析過程中可能會對文件內容進行分析和存儲。<br/>3. 我們承諾保護您的隱私，並採取措施確保數據的安全。<br/><br/><span style="color:#999;font-size:12px;">如果您不同意，請點擊“取消”以終止上傳過程。</span>',
    'privacy.modal.upload.submit': '確認上傳',
    'privacy.modal.upload.cancel': '取消',
    'confirm.modal.quit.title': '確認退出嗎？',
    'confirm.modal.quit.content': '退出會清空所有已輸入內容，是否確認退出？',
    'confirm.modal.quit.submit': '確認',

    'confirm.modal.addlink.title': '添加鏈接',
    'confirm.modal.addlink.error': '鏈接錯誤',
    'confirm.modal.addlink.error.duplicate': '鏈接重複',

    'confirm.modal.addlink.placeholder': '請在此處輸入或粘貼文章鏈接',

    'inputbox.ddl.upload.file': '上傳文件',
    'inputbox.ddl.insert.Link': '添加鏈接',
    'handle.serve.error.message': '嘿嘿，伺服器開小差了，請稍後再試～',

    'copy.success.toast': '內容已複製！',

    'ai.writing.input.label.user.instruction': '用戶指令',
    'ai.writing.input.label.user.instruction.tooltip': '按 Shift + Enter 鍵以開始新行',
    'ai.writing.input.label.reference': '參考內容',
    'ai.writing.input.placeholder.reference': '黏貼文章、內容',
    'ai.writing.input.enable_network_search': '聯網搜索',

    'ai.writing.type.customize': '自定義',
    'ai.writing.type.customize.rewrite': '自定義改寫',
    'ai.writing.type.customize.continue': '自定義續寫',

    'ai.writing.type.entry': '選擇編輯方式',

    'ai.writing.translate.simplified': '簡體',
    'ai.writing.translate.english': '英語',
    'ai.writing.translate.traditional': '繁體',
  },
};
