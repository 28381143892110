import { ReactNode } from 'react';

import defaultLocale from '../locale';

const locale = (key: string): string => {
  const lang = localStorage.getItem('arco-lang') || 'en-US';
  return defaultLocale[lang][key];
};

export const validator = {
  // 手机号校验
  checkPhone(value: string | undefined, callback: (error?: ReactNode) => void) {
    const phoneReg = /^1[34578]\d{9}$/;
    if (value !== '') {
      if (!phoneReg.test(value as string)) {
        callback(locale('validator.phone.error'));
      } else {
        callback();
      }
    } else {
      callback(locale('validator.phone.required'));
    }
  },
  //   邮箱校验
  checkEmail(value: string | undefined, callback: (error?: ReactNode) => void) {
    // const emailReg = /^\w+@[0-9a-z]+\.[a-z]+$/;
    const emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

    if (value !== '') {
      if (!emailReg.test(value as string)) {
        callback(locale('validator.email.error'));
      } else {
        callback();
      }
    } else {
      callback(locale('validator.email.required'));
    }
  },
  //   重置密码校验
  checkPassword(value: string | undefined, callback: (error?: ReactNode) => void) {
    // const passwordReg =
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.?!@#$%^&*()_+\-=]).{8,}$/;
    // (?=.*[a-z]): 至少包含一个小写字母。
    // (?=.*[A-Z]): 至少包含一个大写字母。
    // (?=.*\d): 至少包含一个数字。
    // (?=.*[!@#$%^&*()_+\-=]): 至少包含一个特殊字符。
    // .{8,}: 密码长度至少为8个字符。

    if (value) {
      if (!passwordRegex.test(value)) {
        callback(locale('pwd_change_input_pwd_error'));
      } else {
        callback();
      }
    } else {
      callback(locale('pwd_change_password_required'));
    }
  },

  checkUrl(value: string) {
    // console.log(value);
    // const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
    // const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
    const urlRegex = new RegExp('^[a-zA-z]+://[^\\s]*$');

    if (!value) {
      return false;
    }
    // 正则匹配通过返回true, 否则返回false
    return urlRegex.test(value);

    // try {
    //   if (urlRegex.test(value)) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } catch (error) {
    //   return false;
    // }
  },
};
