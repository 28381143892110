import PassportApi from '@hkgai/pb-fe-api/lib/passport_api/passport_api';
import { REQUEST_TIMEOUT, SERVICE_HOST } from '@config/constant';
import HttpServer, { interceptorRequestConfig, interceptorRequestError, interceptorResponseConfig, interceptorResponseError } from './request';

// export const fetchResetUserPassword = async (params: ResetUserPasswordReq): Promise<ResetUserPasswordResp> => {
//   return await httpServer.post(SINGER_SERVICE.resetUserPassword, params);
// };

export const SINGER_SERVICE = {
  getAccountInfo: '/passport/api/get_user_info',
  userLogin: '/passport/web/user_login',
  userLogOut: '/passport/api/web_user_login_out',
  mobileUserLogin: '/passport/mobile/user_login',
  resetUserPassword: '/passport/api/reset_user_password',
  userLoginOut: '/passport/api/user_login_out',
  forgotPwd: 'passport/api/forgot_password',
  resetPassword: '/passport/api/reset_password',
};
const httpServer = new HttpServer('/');
// 配置通用拦截器
httpServer.serviceInstance.interceptors.request.use(interceptorRequestConfig, interceptorRequestError);
httpServer.serviceInstance.interceptors.response.use(interceptorResponseConfig, interceptorResponseError);

export interface GetUserInfoReq {}

export interface UserInfo {
  accountId?: string;
  screenName?: string;
  createTime?: number;
  email?: string;
}
export interface GetUserInfoResp {
  userInfo: UserInfo;
}

export interface UserLoginReq {
  account: string;
  password: string;
}

export interface UserLoginResp {}

export interface UserLogOutReq {}
export interface UserLogOutResp {}

// web用户登录
export const fetchUserLogin = async (params: UserLoginReq): Promise<UserLoginResp> => {
  return await httpServer.post(SINGER_SERVICE.userLogin, params);
};

// web用户登出
export const fetchUserLogOut = async (params: UserLogOutReq): Promise<UserLogOutResp> => {
  return await httpServer.post(SINGER_SERVICE.userLoginOut, params);
};

// 获取用户信息
export const fetchUserInfo = async (params: GetUserInfoReq): Promise<GetUserInfoResp> => {
  return await httpServer.post(SINGER_SERVICE.getAccountInfo, params);
};

export interface MobileUserInfoReq {
  account: string;
  password: string;
}

export interface MobileUserInfoResp {
  token: string;
  expire?: string;
}

// 获取用户信息
export const fetchMobileUserInfo = async (params: MobileUserInfoReq): Promise<MobileUserInfoResp> => {
  return await httpServer.post(SINGER_SERVICE.mobileUserLogin, params);
};

// resetUserPassword
export interface ResetUserPasswordReq {
  newPassword?: string;
  token?: string;
  distinctId?: string;
  captcha?: string;
}
export interface ResetUserPasswordResp {}

export const fetchResetUserPassword = async (params: ResetUserPasswordReq): Promise<ResetUserPasswordResp> => {
  return await httpServer.post(SINGER_SERVICE.resetUserPassword, params);
};

//
export const passportApi = new PassportApi(SERVICE_HOST, {
  timeout: REQUEST_TIMEOUT,
});

//  忘记密码
export interface ForgotPwdReq {
  email: string;
}

export interface ForgotPwdResp {}

export const fetchForgotPwd = async (params: ForgotPwdReq): Promise<ForgotPwdResp> => {
  return await httpServer.post(SINGER_SERVICE.forgotPwd, params);
};

// 重置密码
export interface ResetPasswordRsq {
  reset_token: string;
  email?: string;
  new_password: string;
}

export interface ResetPasswordResp {}

export const fetchResetPassword = async (params: ResetPasswordRsq): Promise<ResetPasswordResp> => {
  return await httpServer.post(SINGER_SERVICE.resetPassword, params);
};
