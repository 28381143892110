export const proofreadingI18n: { [key: string]: any } = {
  'en-US': {
    proofreading_title: 'Proofreading Plan',
    proofreading_no_content: 'Congratulations, no issues were found in this proofreading',
    proofreading_start_state: 'Please enter your content on the left to start proofreading.',
    proofreading_go_start: 'Please click the button in the upper right corner to start proofreading.',
    proofread_dropdown_list_suggest_replace: 'Better Be',
    proofread_dropdown_list_issue_description: 'Issue Description：',
    proofread_dropdown_list_button_ignore: 'Dismiss',
    proofreading_success: 'Proofreading successful',
    proofreading: 'Proofreading',
    under_proofreading: 'Carefully examining your content, please wait',
    proofreading_accept: 'Accept',
    proofreading_view: 'View',
    proofreading_all_issues: 'All Issues',

    proofreading_proper_names: 'Proper Names',

    proofreading_punctuation: 'Punctuation',
    proofreading_organizations: 'Organizations',
    proofreading_events: 'Events',

    proofreading_common_errors: 'General Accuracy',

    proofreading_persons: 'Persons',
    proofreading_typo: 'Typo',
    proofreading_grammar: 'Grammar',

    proofread_ignore_all: 'Dismiss All',
  },
  'zh-CN': {
    proofreading_title: '校对方案',
    proofreading_no_content: '恭喜您，本次校对没有发现问题',
    proofreading_start_state: '请在左侧输入内容，开始校对',
    proofreading_go_start: '请点击右上角按钮开始校对',
    proofread_dropdown_list_suggest_replace: '建议替换为',
    proofread_dropdown_list_issue_description: '问题描述：',
    proofread_dropdown_list_button_ignore: '忽略',
    proofreading_success: '校对成功',
    proofreading: '校对',
    under_proofreading: '正在仔细检查您的内容，请稍候',
    proofreading_accept: '采纳',
    proofreading_view: '查看',
    proofreading_all_issues: '全部问题',

    proofreading_proper_names: '专有名称',

    proofreading_persons: '人物',
    proofreading_organizations: '组织机构',
    proofreading_events: '事件',

    proofreading_common_errors: '基础语言规范',

    proofreading_punctuation: '标点符号',
    proofreading_typo: '拼写错误',
    proofreading_grammar: '语法错误',

    proofread_ignore_all: '全部忽略',
  },
  'zh-HK': {
    proofreading_title: '校對方案',
    proofreading_no_content: '恭喜您，本次校對沒有發現問題',
    proofreading_start_state: '請在左側輸入內容，開始校對',
    proofreading_go_start: '請點擊右上角按鈕開始校對',
    proofread_dropdown_list_suggest_replace: '建議替換為',
    proofread_dropdown_list_issue_description: '問題描述：',
    proofread_dropdown_list_button_ignore: '忽略',
    proofreading_success: '校對成功',
    proofreading: '校對',
    under_proofreading: '正在仔細檢查您的內容，請稍候',
    proofreading_accept: '採納',
    proofreading_view: '查看',
    proofreading_all_issues: '全部問題',

    proofreading_proper_names: '專有名稱',

    proofreading_persons: '人物',
    proofreading_organizations: '組織機構',
    proofreading_events: '事件',

    proofreading_common_errors: '基礎語言規範',

    proofreading_punctuation: '標點符號',
    proofreading_typo: '拼寫錯誤',
    proofreading_grammar: '語法錯誤',

    proofread_ignore_all: '全部忽略',
  },
};
