import { model } from '@modern-js/runtime/model';
import { Message } from '@arco-design/web-react';
import { fetchUserLogOut } from '@api/copilot_api';
import { UserInfo } from '@hkgai/pb-fe-api/lib/interface/copilot_api';
import { Resource, ResourceType } from '@/components/upload-modal';
import { Sidebars, SidebarStatus, SidebarType } from '@/type/copilot';
import { getSubscribe } from '@/api/copilot_api';

interface Props {
  userInfo: UserInfo;
  sideBar?: Sidebars[];
  resources?: Resource[];
  selectFiles?: Resource[]; // 选中的资源
  selectReferences?: Resource[]; // 用户添加的linkxxxw
  sidebarType: SidebarType;
  aiBotVisible: boolean;
  activeAiBot: boolean;
  sideBarReqFinish: boolean;
}

const GlobalStore = model<Props>('GlobalStore').define({
  state: {
    userInfo: {},
    resources: [],
    uploadFiles: [],
    selectUploadFiles: [],
    sideBar: [],
    sidebarType: SidebarType.UNKNOWN,
    aiBotVisible: false,
    activeAiBot: false,
    sideBarReqFinish: false,
  },
  actions: {
    setUser(draft, data) {
      draft.userInfo = data;
    },

    // 资源
    setAddResources(draft, payload) {
      draft.resources = payload;
    },

    // 选中的上传资源
    setSelectFiles(draft, payload) {
      draft.selectFiles = payload;
    },

    // 选中的上传资源
    setSelectReferences(draft, payload) {
      draft.selectReferences = payload;
    },

    //  user login out
    onLogOut: {
      pending(draft) {
        console.log(draft);
      },
      fulfilled(draft, data) {
        console.log(draft, data);
        // aiwriting 数据需要保存到本地，所以去掉清除操作
        // localStorage.clear();
        localStorage.setItem('USER_ID', '');
        sessionStorage.clear();
      },
      rejected(draft, error) {
        Message.error(`code: ${error.code}, msg:${error.msg}` || '操作失败，请稍后再试');
        console.log(draft, error);
      },
    },

    //  get Sidebar config
    fetchSideBar: {
      pending(draft) {
        console.log(draft);
        draft.sideBarReqFinish = false;
      },
      fulfilled(draft, data: Sidebars[]) {
        draft.sideBar = data.map(item => ({ ...item, status: item.status === SidebarStatus.DISABLED ? item.status : SidebarStatus.ENABLED }));
        draft.sideBarReqFinish = true;
      },
      rejected(draft, error) {
        Message.error(`code------>: ${error.code}, msg:${error.msg}` || '操作失败，请稍后再试');
        draft.sideBarReqFinish = true;
        console.log(draft, error);
      },
    },

    // 切换右侧边栏对应的功能
    changeSideBarType(draft, payload) {
      draft.sidebarType = payload;
    },

    // 切换ai bot弹窗打开状态
    changeAiBotVisible(draft, payload) {
      draft.aiBotVisible = payload;
    },

    changeActiveAiBot(draft, payload) {
      draft.activeAiBot = payload;
    },
  },
  computed: {
    fileList: state => {
      return (state?.resources?.length && state.resources.filter(item => item.type === ResourceType.FILE)) || [];
    },
    references: state => {
      return (state?.resources?.length && state.resources.filter(item => item.type === ResourceType.ATTACHMENT)) || [];
    },
    isSiderBarDrawerVisible: state => {
      return state.sidebarType !== SidebarType.UNKNOWN && state.sidebarType !== SidebarType.COPILOT;
    },
  },
  effects: {
    async onLogOut(callbacks?: VoidFunction) {
      const data = await fetchUserLogOut({});
      callbacks?.();
      return data;
    },

    async fetchSideBar() {
      const { sidebars = [] } = await getSubscribe({});
      return sidebars;
    },
  },
});

export default GlobalStore;
