const useUrlParams = () => {
  const url = window.location.href ?? location.href;
  const Params: { [key: string]: any } = {};
  if (url.indexOf('?') > 0) {
    // 判断是否有qurey
    const parmas = url.slice(url.indexOf('?') + 1); // 截取出query
    const paramlists = parmas.split('&'); // 分割键值对
    for (const param of paramlists) {
      const a = param.split('=');
      Object.assign(Params, { [a[0]]: a[1] }); // 将键值对封装成对象
    }
  }
  return Params;
};

export default useUrlParams;
