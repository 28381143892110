import './index.scss';

import { Menu, Message, Modal } from '@arco-design/web-react';
import { useNavigate } from '@modern-js/runtime/router';
import { FC } from 'react';
import GlobalStore from '@store/globalStore';
import { useModel } from '@modern-js/runtime/model';
import { UserDept } from '@type/copilot';
import useLocale from '@hooks/useLocale';
import { IconBodyText, IconEditBtn, IconExportBtn, IconInfoCircleBtn } from '@arco-iconbox/react-hkgai-government-icon';
import { IconCustomerService, IconEmail, IconExclamationCircle, IconPhone } from '@arco-design/web-react/icon';
import { copyTextUsingClipboardAPI } from '@/views/writing/util';

// const { SubMenu } = Menu;
// const MenuItem = Menu.Item;

interface UserDropdownProps {
  data?: UserDept[];
}

const UserDropdown: FC<UserDropdownProps> = () => {
  const navigate = useNavigate();
  const locale = useLocale();

  const [, actions] = useModel(GlobalStore);

  const onClickMenuItem = (key: string) => {
    switch (key) {
      case 'changePassword':
        navigate('/pwd/reset');
        break;
      case 'logout':
        actions.onLogOut(() => navigate('/login', { replace: true }));
        break;
      case 'termOfUse':
        Modal.info({
          icon: null,
          wrapClassName: 'term-of-use-modal',
          title: 'Terms of Use',
          content:
            'The application is provided for informational purposes only and should not be relied upon for making legally binding decisions, with users advised to verify critical information through official channels and adhere to all relevant data privacy and ethical guidelines. The application expressly disclaims any responsibility for inaccuracies or misapplication of the information provided.',
        });
        break;
      case 'ITSupport':
        Modal.info({
          icon: null,
          wrapClassName: 'term-of-use-modal',
          style: { width: '400px' },
          title: 'IT Support',
          content: (
            <div className="flex flex-col justify-between gap-y-[8px]  leading-[20px]">
              <div className="flex">
                <div className="h-[20px] flex items-center justify-center">
                  <IconEmail className="mr-[8px] text-[16px]" />
                </div>
                <div className="flex-1">service@hkgai.org</div>
              </div>
              <div className="flex">
                <div className="h-[20px] flex items-center justify-center">
                  <IconPhone className="mr-[8px] text-[16px]" />
                </div>
                <div className="flex-1">3469 3249</div>
              </div>
              <div className="flex">
                <div className="h-[20px] flex items-center justify-center">
                  <IconExclamationCircle className="mr-[8px] text-[16px]" />
                </div>
                <div className="flex-1">
                  {locale.user_dropdown_menu_it_support_time}
                  <br />
                  {locale.user_dropdown_menu_it_support_language}
                </div>
              </div>
            </div>
          ),
        });
        break;
      case 'fqa':
        window.open('https://hkgpt-application.sg.larksuite.com/docx/UichdAOCOoku1HxuYvsleEgdgYd', '_black');
        break;
      case 'email':
        copyTextUsingClipboardAPI('gaoxuxu@hkgai.org', () => Message.success({ content: locale['copy.success.toast'] }));
        break;
      case 'phoneNumber':
        copyTextUsingClipboardAPI('51306410', () => Message.success({ content: locale['copy.success.toast'] }));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Menu onClickMenuItem={onClickMenuItem} mode="pop" className="menu-wrap">
        {/* <Menu.Item key="fqa" className="menu-item-hover">
          <IconQuestionCircle /> FAQ
        </Menu.Item> */}
        <Menu.Item key="changePassword" className="menu-item-hover">
          <IconEditBtn /> {locale.user_dropdown_menu_item_change_password}
        </Menu.Item>
        <Menu.Item key="termOfUse" className="menu-item-hover">
          <IconBodyText /> Terms of Use
        </Menu.Item>
        <Menu.Item key="ITSupport" className="menu-item-hover">
          <IconCustomerService /> IT Support
        </Menu.Item>
        <Menu.Item key="app_version" className="menu-item-hover" disabled>
          <IconInfoCircleBtn /> {`v ${process.env.APP_VERSION}`}
        </Menu.Item>
        {/* <Menu.Item key="email" className="menu-item-hover">
          <IconEmail /> gaoxuxu@hkgai.org
        </Menu.Item>
        <Menu.Item key="phoneNumber" className="menu-item-hover">
          <IconPhone /> 51306410
        </Menu.Item> */}
        <Menu.Item key="logout" className="menu-item-hover">
          <IconExportBtn /> {locale.user_dropdown_menu_item_log_out}
        </Menu.Item>
      </Menu>
    </>
  );
};

export default UserDropdown;
