export const TOKEN_KEY = 'TOKEN';
export const TOKEN_EXPIRE_KEY = 'TOKEN_EXPIRE';

export const USER_INFO_KEY = 'COPILOT_USER_INFO';
export const USER_BLOCKED_KEY = 'USER_BLOCKED';

export const AUTH_STATUS_KEY = 'AUTH_STATUS_KEY';

export const SYSTEM_INIT_KEY = 'SYSTEM_INIT_KEY';

// appid
export const SERVER_APP_ID = 101;
export const SERVICE_HOST = '/';
// api 超时&重试
export const REQUEST_TIMEOUT = 50000;

export enum AuthorizationStatus {
  Authenticated = '1',
  Unauthenticated = '0',
}

// AI 写作reference上传文件
export const AI_WRITING_MAX_REFERENCE_FILE_SIZE = 10 * 1024 * 1024;

// 用户prompt的最大长度
export const MAX_USER_PROMPT_LEN = 1000;
export const MAX_USER_REFERENCE_LEN = 5000;
