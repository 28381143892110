import './index.css';
import '@/styles/global.scss';

import { Outlet } from '@modern-js/runtime/router';
import { Provider } from '@modern-js/runtime/model';
import { Helmet } from '@modern-js/runtime/head';
import { IconHkCopilot, IconNotification } from '@arco-iconbox/react-hkgai-government-icon';
import { GlobalProvider } from './globalProvider';
import { UserAuthProvider } from './auth';
import { getSystem } from '@/utils';

export default function Layout() {
  const systemInfo = getSystem();

  return (
    <>
      <Helmet>
        <title>Copilot</title>
      </Helmet>

      <Provider>
        <GlobalProvider>
          <UserAuthProvider>
            <>
              {systemInfo === 'pc' ? (
                <Outlet />
              ) : (
                <div className="h5-page">
                  <div className="h5-page-title">Empower your talent.</div>
                  <IconHkCopilot style={{ width: '129px', height: '36px' }} />
                  <div className="sub-title">To start your AI journey</div>
                  <div className="page-body">https://copilot.hkgai.net/login</div>
                  <div className="h5-page-message">
                    <IconNotification />
                    <div style={{ flex: 1 }}> We recommend using the web browser on your desktop computer or laptop for the best user experience.</div>
                  </div>
                  <div className="h5-page-footer">Power by HKGAI</div>
                </div>
              )}
            </>
          </UserAuthProvider>
        </GlobalProvider>
      </Provider>
    </>
  );
}
