import useStorage from '@hooks/useStorage';
import enUS from '@arco-design/web-react/es/locale/en-US';
import zhHK from '@arco-design/web-react/es/locale/zh-HK';
import zhCN from '@arco-design/web-react/es/locale/zh-CN';
import { ConfigProvider } from '@arco-design/web-react';
import { useRef } from 'react';
import { SERVER_APP_ID } from '@config/constant';
import { useModel } from '@modern-js/runtime/model';
import GlobalStore from '@store/globalStore';
import TrackerSDK, { SDKOptions, TrackEvent, User } from '@hkgai/tracker-sdk';
import { GlobalContext } from './context';

export const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [{ userInfo = {} }] = useModel(GlobalStore);
  const [lang, setLang] = useStorage('arco-lang', 'en-US');
  const trackerSDKRef = useRef<any>();

  const sdkOptions: SDKOptions = {
    appId: `${SERVER_APP_ID}`,
    log: false,
    autoTrack: true,
    appName: 'copilot_web',
  };
  const trackerSDK = new TrackerSDK.HkGaiTrackerSDK(sdkOptions);
  trackerSDKRef.current = trackerSDK;

  const getArcoLocale = () => {
    switch (lang) {
      case 'zh-CN':
        return zhCN;
      case 'en-US':
        return enUS;
      case 'zh-HK':
        return zhHK;
      default:
        return enUS;
    }
  };

  const collectEvent = (events: TrackEvent[]) => {
    trackerSDKRef.current.collectEvent(events);
  };

  const setUserInfo = (user: User) => {
    trackerSDKRef.current.setUser(user);
  };

  const contextValue = {
    lang,
    setLang,
    userInfo,
    collectEvent,
    setUserInfo,
  };

  const tablePagination = {
    showTotal: true,
    sizeCanChange: true,
    hideOnSinglePage: true,
    pageSizeChangeResetCurrent: true,
  };
  const componentConfig = {
    Pagination: {
      showTotal: true,
      sizeCanChange: true,
      hideOnSinglePage: true,
      pageSizeChangeResetCurrent: true,
      showJumper: false,
    },
    Table: {
      border: false,
      stripe: true,
    },
    Drawer: {
      width: 560,
      unmountOnExit: true,
      autoFocus: false,
      mountOnEnter: false,
    },
    Modal: {
      autoFocus: false,
      unmountOnExit: true,
      focusLock: false,
    },
  };

  return (
    <ConfigProvider locale={getArcoLocale()} tablePagination={tablePagination} componentConfig={componentConfig}>
      <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>
    </ConfigProvider>
  );
};
