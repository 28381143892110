import { createContext } from 'react';
import { TrackEvent } from '@hkgai/tracker-sdk';
import { UserInfo } from '@hkgai/pb-fe-api/lib/interface/copilot_api';

export const GlobalContext = createContext<{
  lang?: string;
  user?: UserInfo;
  setLang?: (value: string) => void;
  collectEvent?: (events: TrackEvent[]) => void;
  setUserInfo?: (user: UserInfo) => void;
}>({});
