export const loginI18n: { [key: string]: any } = {
  'en-US': {
    login_slogan: 'Sign In',
    login_form_account_placeholder: 'Your email address',
    login_form_password_placeholder: 'Password',
    login_form_submit_button: 'Sign In',
    login_form_validation_account_required: 'Your email address',
    login_form_validation_password_required: 'Password',
    login_form_forgot_password_button: 'Forgot your password?',
    login_wrong_login: 'Email or password error.',
    login_user_blocked: 'Account disabled. Please contact admin to unlock.',
    login_user_password_wrong_limit: 'Exceeded incorrect password attempts. Please try again in 10 minutes.',
    login_validator_email_error: 'Invalid email address. Please check.',
    login_err_user_unactivate: 'Account not activated.',
  },
  'zh-CN': {
    login_slogan: '登录',
    login_form_account_placeholder: '请输入邮箱账号',
    login_form_password_placeholder: '请输入密码',
    login_form_submit_button: '登录',
    login_form_validation_account_required: '请输入邮箱账号',
    login_form_validation_password_required: '请输入密码',
    login_form_forgot_password_button: '忘记密码',
    login_wrong_login: '账户名称或者密码错误',
    login_user_blocked: '账户已被禁用，请联系管理员解禁',
    login_user_password_wrong_limit: '连续输出密码错误超出上限，10分钟后再试',
    login_validator_email_error: '邮箱地址有误，请检查',
    login_err_user_unactivate: '账户未激活',
  },
  'zh-HK': {
    login_slogan: '登入',
    login_form_account_placeholder: '請輸入電子郵件帳號',
    login_form_password_placeholder: '請輸入密碼',
    login_form_submit_button: '登入',
    login_form_validation_account_required: '請輸入電子郵件帳號',
    login_form_validation_password_required: '請輸入密碼',
    login_wrong_login: '賬戶名稱或者密碼錯誤',
    login_user_blocked: '賬戶已被禁用，請聯繫管理員解禁',
    login_form_forgot_password_button: '忘記密碼',
    login_user_password_wrong_limit: '連續輸出密碼錯誤超出上限，10分鐘後再試',
    login_validator_email_error: '電郵地址有誤，請檢查',
    login_err_user_unactivate: '賬戶未啟用',
  },
};
