import {
  ALIGN_KEY,
  BOLD_KEY,
  CLEAR_KEY,
  FONT_SETTING_KEY,
  HEADING_KEY,
  ITALIC_KEY,
  LINE_HEIGHT_CUSTOM_KEY,
  ORDERED_LIST_KEY,
  PARAGRAPH_KEY,
  STRIKE_THROUGH_KEY,
  STYLE_SETTING_KEY,
  UNDERLINE_KEY,
  UNORDERED_LIST_KEY,
} from '@hkgai/slate-plugin/dist/lib/plugins';
import { CustomEventOptions, DoActions } from '../type/toolsbar';

export const toolsbarI18n: { [key: string]: any } = {
  'en-US': {
    [PARAGRAPH_KEY]: 'Body Text',
    [`${HEADING_KEY}.h1`]: 'Heading 1',
    [`${HEADING_KEY}.h2`]: 'Heading 2',
    [`${HEADING_KEY}.h3`]: 'Heading 3',
    [`${HEADING_KEY}.h4`]: 'Heading 4',
    [`${HEADING_KEY}.h5`]: 'Heading 5',
    [UNORDERED_LIST_KEY]: 'Bulleted list',
    [`${ALIGN_KEY}.left`]: 'Align Left',
    [`${ALIGN_KEY}.center`]: 'Align Centre',
    [`${ALIGN_KEY}.right`]: 'Align Right',
    [`${ALIGN_KEY}.justify`]: 'Justify',
    [LINE_HEIGHT_CUSTOM_KEY]: 'Line height',
    [BOLD_KEY]: 'Bold',
    [STRIKE_THROUGH_KEY]: 'Strikethrough',
    [ITALIC_KEY]: 'Italic',
    [UNDERLINE_KEY]: 'Underline',
    [ORDERED_LIST_KEY]: 'Numbered list',
    [FONT_SETTING_KEY]: 'Font size',
    [STYLE_SETTING_KEY]: 'Font colour',
    [CLEAR_KEY]: 'Clear formatting',
    [DoActions.REDO]: 'Redo',
    [DoActions.UNDO]: 'Undo',
    [CustomEventOptions.DELETE]: 'Delete',
    [CustomEventOptions.DOWNLOAD]: 'Download',
    'toolsbar.textColour': 'Text colour',
    'toolsbar.backgroundColour': 'Background colour',
    'toolsbar.reset': 'Reset',
    'toolsbar.insertContents': 'Insert contents',
    'toolsbar.createTable': 'Create a table',
    'toolsbar.insertImage': 'Insert image',
  },
  'zh-CN': {
    [PARAGRAPH_KEY]: '正文',
    [`${HEADING_KEY}.h1`]: '一级标题',
    [`${HEADING_KEY}.h2`]: '二级标题',
    [`${HEADING_KEY}.h3`]: '三级标题',
    [`${HEADING_KEY}.h4`]: '四级标题',
    [`${HEADING_KEY}.h5`]: '五级标题',
    [UNORDERED_LIST_KEY]: '项目符号列表',
    [`${ALIGN_KEY}.left`]: '左对齐',
    [`${ALIGN_KEY}.center`]: '居中对齐',
    [`${ALIGN_KEY}.right`]: '右对齐',
    [`${ALIGN_KEY}.justify`]: '两端对齐',
    [LINE_HEIGHT_CUSTOM_KEY]: '行高',
    [BOLD_KEY]: '粗体',
    [STRIKE_THROUGH_KEY]: '删除线',
    [ITALIC_KEY]: '斜体',
    [UNDERLINE_KEY]: '下划线',
    [ORDERED_LIST_KEY]: '标号列表',
    [FONT_SETTING_KEY]: '字号',
    [STYLE_SETTING_KEY]: '字体颜色',
    [CLEAR_KEY]: '清除格式',
    [DoActions.REDO]: '重做',
    [DoActions.UNDO]: '撤销',
    [CustomEventOptions.DELETE]: '删除',
    [CustomEventOptions.DOWNLOAD]: '下载',
    'toolsbar.textColour': '文本颜色',
    'toolsbar.backgroundColour': '背景颜色',
    'toolsbar.reset': '重置',
    'toolsbar.insertContents': '插入内容',
    'toolsbar.createTable': '创建表格',
    'toolsbar.insertImage': '插入图片',
  },
  'zh-HK': {
    [PARAGRAPH_KEY]: '正文',
    [`${HEADING_KEY}.h1`]: '一级標題',
    [`${HEADING_KEY}.h2`]: '二级標題',
    [`${HEADING_KEY}.h3`]: '三级標題',
    [`${HEADING_KEY}.h4`]: '四级標題',
    [`${HEADING_KEY}.h5`]: '五级標題',
    [UNORDERED_LIST_KEY]: '項目符號列表',
    [`${ALIGN_KEY}.left`]: '左對齊',
    [`${ALIGN_KEY}.center`]: '居中對齊',
    [`${ALIGN_KEY}.right`]: '右對齊',
    [`${ALIGN_KEY}.justify`]: '兩端對齊',
    [LINE_HEIGHT_CUSTOM_KEY]: '行高',
    [BOLD_KEY]: '粗體',
    [STRIKE_THROUGH_KEY]: '刪除線',
    [ITALIC_KEY]: '斜體',
    [UNDERLINE_KEY]: '下劃線',
    [ORDERED_LIST_KEY]: '標號列表',
    [FONT_SETTING_KEY]: '字號',
    [STYLE_SETTING_KEY]: '字體顔色',
    [CLEAR_KEY]: '清除格式',
    [DoActions.REDO]: '重做',
    [DoActions.UNDO]: '撤銷',
    [CustomEventOptions.DELETE]: '刪除',
    [CustomEventOptions.DOWNLOAD]: '下載',
    'toolsbar.textColour': '文本顔色',
    'toolsbar.backgroundColour': '背景顔色',
    'toolsbar.reset': '重置',
    'toolsbar.insertContents': '插入內容',
    'toolsbar.createTable': '建立表格',
    'toolsbar.insertImage': '插入圖片',
  },
};
