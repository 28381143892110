export const promptHistoryI18n: { [key: string]: any } = {
  'en-US': {
    'prompt.history.select.empty': 'No previous prompts',
    'prompt.history.trigger.text': 'History',
  },
  'zh-CN': {
    'prompt.history.select.empty': '暂无历史用户指令',
    'prompt.history.trigger.text': '历史',
  },
  'zh-HK': {
    'prompt.history.select.empty': '暫無歷史用戶指令',
    'prompt.history.trigger.text': '歷史',
  },
};
