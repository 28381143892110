import axios from 'axios';
import { UploadItem } from '@arco-design/web-react/es/Upload';
import CopilotApi from '@hkgai/pb-fe-api/lib/copilot_api/copilot_api';
import { REQUEST_TIMEOUT, SERVICE_HOST } from '@config/constant';
import {
  AdvancedWritingArticleGenerateReq,
  AdvancedWritingOutlineGenerateReq,
  AdvancedWritingParagraphGenerateReq,
  AdvancedWritingParagraphOutlineGenerateReq,
  AdvancedWritingArticleGenerateResp,
  AdvancedWritingOutlineGenerateResp,
  AdvancedWritingParagraphGenerateResp,
  AdvancedWritingParagraphOutlineGenerateResp,
  CreateAndSaveAiWritingReq,
  CreateAndSaveAiWritingResp,
  CreateOutlineWritingReq,
  CreateOutlineWritingResp,
  DeleteConversationReq,
  DeleteConversationResp,
  ForgotPasswordReq,
  ForgotPasswordResp,
  GetGlobalNotificationReq,
  GetGlobalNotificationResp,
  GetUserInfoReq,
  GetUserInfoResp,
  InfoReq,
  InfoResp,
  QueryAiWritingReq,
  QueryAiWritingResp,
  QueryOutlineWritingReq,
  QueryOutlineWritingResp,
  RemoveAiWritingReq,
  RemoveAiWritingResp,
  RemoveOutlineWritingReq,
  RemoveOutlineWritingResp,
  ResetPasswordReq,
  ResetPasswordResp,
  ResetUserPasswordReq,
  ResetUserPasswordResp,
  SendInstructionReportReq,
  SendInstructionReportResp,
  SensitiveVerifyReq,
  SensitiveVerifyResp,
  SidebarRequest,
  SidebarResponse,
  TextCheckRequest,
  TextCheckResponse,
  UpdateOutlineWritingNameReq,
  UpdateOutlineWritingNameResp,
  UserLoginOutReq,
  UserLoginOutResp,
  UserLoginReq,
  UserLoginResp,
  AdvancedWritingOutlineSaveReq,
  AdvancedWritingOutlineSaveResp,
  AdvancedWritingOutlineQueryReq,
  AdvancedWritingOutlineQueryResp,
  CopyOutlineWritingReq,
  CopyOutlineWritingResp,
  EditOutlineWritingInfoReq,
  EditOutlineWritingInfoResp,
  AdvancedWritingKeywordsGenerateReq,
  AdvancedWritingKeywordsGenerateResp,
  QueryOutlineWritingInfoReq,
  QueryOutlineWritingInfoResp,
  AdvancedWritingParagraphOutlineCreateReq,
  AdvancedWritingParagraphOutlineCreateResp,
  AdvancedWritingParagraphOutlineDeleteReq,
  AdvancedWritingParagraphOutlineDeleteResp,
  NeedScoreFeedbackReq,
  NeedScoreFeedbackResp,
  AdvancedWritingParagraphContentCreateReq,
  AdvancedWritingParagraphContentCreateResp,
} from '@hkgai/pb-fe-api/lib/interface/copilot_api';
import { ContentType, interceptorRequestConfig, interceptorRequestError, interceptorResponseConfig, interceptorResponseError } from './request';

//
export const copilotApi = new CopilotApi(SERVICE_HOST, {
  timeout: REQUEST_TIMEOUT,
});

// 配置通用拦截器
copilotApi.serviceInstance.interceptors.request.use(interceptorRequestConfig, interceptorRequestError);
copilotApi.serviceInstance.interceptors.response.use(interceptorResponseConfig, interceptorResponseError);

/**
 * 查询用户个人信息
 * 描述：获取用户所在部门以及用户角色
 * @param  InfoReq
 * @returns  GET /admin/info
 */
export const getUserInfo = async (params: InfoReq) => {
  return (await copilotApi.Info(params)) as Promise<InfoResp>;
};

/**
 * 用户认证（邮件验证码）
 * POST /admin/sensitive/verify
 * @param params
 * @returns
 */
export const sendVerify = async (params: SensitiveVerifyReq) => {
  return (await copilotApi.SensitiveVerify(params)) as Promise<SensitiveVerifyResp>;
};

export const chatResultReport = async (params: SendInstructionReportReq) => {
  return (await copilotApi.SendInstructionFeedback(params)) as Promise<SendInstructionReportResp>;
};

// 获取通知
export const getNotification = async (params: GetGlobalNotificationReq) => {
  return (await copilotApi.GetGlobalNotification(params)) as Promise<GetGlobalNotificationResp>;
};

// 获取通知
export const getSubscribe = async (params: SidebarRequest) => {
  return (await copilotApi.Sidebar(params)) as Promise<SidebarResponse>;
};

export const fetchProofreadingCheck = async (params: TextCheckRequest) => {
  return (await copilotApi.TextCheck(params)) as Promise<TextCheckResponse>;
};

export const fetchDeleteConversation = async (params: DeleteConversationReq) => {
  return (await copilotApi.DeleteConversation(params)) as Promise<DeleteConversationResp>;
};

/**
 * @param  账号相关
 * @returns
 */
export const fetchUserLogin = async (params: UserLoginReq) => {
  return (await copilotApi.UserLogin(params)) as Promise<UserLoginResp>;
};

// web用户登出
export const fetchUserLogOut = async (params: UserLoginOutReq) => {
  return (await copilotApi.UserLoginOut(params)) as Promise<UserLoginOutResp>;
};

// 获取用户信息
export const fetchUserInfo = async (params: GetUserInfoReq) => {
  return (await copilotApi.GetUserInfo(params)) as Promise<GetUserInfoResp>;
};

export const fetchForgotPwd = async (params: ForgotPasswordReq) => {
  return (await copilotApi.ForgotPassword(params)) as Promise<ForgotPasswordResp>;
};

export const fetchResetUserPassword = async (params: ResetUserPasswordReq) => {
  return (await copilotApi.ResetUserPassword(params)) as Promise<ResetUserPasswordResp>;
};

export const fetchResetPassword = async (params: ResetPasswordReq) => {
  return (await copilotApi.ResetPassword(params)) as Promise<ResetPasswordResp>;
};

/** ***** -------------------------------------------------------------------------------- */

export const parseFileToTxt = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);

  return new Promise((resolve, reject) => {
    axios
      .post('/v1/document/parse', formData, {
        headers: {
          'Content-Type': ContentType.FORM_DATA,
          'X-App-Id': 2,
        },
      })
      .then(response => {
        console.log(response.data);
        if (response.data.data) {
          resolve(response.data.data.text);
        }
        resolve('');
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
};

export const sendGlobalFeedback = async (content: string, images: UploadItem[] = []): Promise<string> => {
  const formData = new FormData();
  formData.append('content', content || '');

  if (images.length) {
    images.forEach(file => {
      formData.append('images', file.originFile as File);
    });
  }

  return new Promise((resolve, reject) => {
    axios
      .post('/copilot/api/v1/feedback/global', formData, {
        headers: {
          'Content-Type': ContentType.FORM_DATA,
          'X-App-Id': 2,
        },
      })
      .then(response => {
        console.log(response.data);
        if (response.data.data) {
          resolve(response.data.data);
        }
        resolve('');
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
};

export const fetchDownloadFile = async (title: string, data: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .post('/slate-transform/api/docx', { data, type: 'doc-slate-dom' }, { headers: { 'Content-Type': 'application/json' }, responseType: 'blob' })
      .then(response => {
        if (response.data) {
          console.log('response=====>', response.data);

          resolve(response.data);
        }
        resolve('');
      })
      .catch(error => {
        reject(error);
      });
  });
};

/**
 *
 * 长文写作相关
 *
 */

/// 新增或者保存一篇 ai 写作的文章
export const createAndSaveAiWriting = async (params: CreateAndSaveAiWritingReq) => {
  return (await copilotApi.CreateAndSaveAiWriting(params)) as Promise<CreateAndSaveAiWritingResp>;
};

/// 移除一篇 ai 写作的文章
export const removeAiWriting = async (params: RemoveAiWritingReq) => {
  return (await copilotApi.RemoveAiWriting(params)) as Promise<RemoveAiWritingResp>;
};

/// 分页查询ai写作的文章列表
export const queryAiWriting = async (params: QueryAiWritingReq) => {
  return (await copilotApi.QueryAiWriting(params)) as Promise<QueryAiWritingResp>;
};

// 新增一篇 大纲写作
export const createOutlineWriting = async (params: CreateOutlineWritingReq) => {
  return (await copilotApi.CreateOutlineWriting(params)) as Promise<CreateOutlineWritingResp>;
};

// 复制一篇 大纲写作
export const copyOutlineWriting = async (params: CopyOutlineWritingReq) => {
  return (await copilotApi.CopyOutlineWriting(params)) as Promise<CopyOutlineWritingResp>;
};

// 更新一篇大纲写作的标题
export const updateOutlineWritingName = async (params: UpdateOutlineWritingNameReq) => {
  return (await copilotApi.UpdateOutlineWritingName(params)) as Promise<UpdateOutlineWritingNameResp>;
};

// 移除一篇大纲写作
export const removeOutlineWriting = async (params: RemoveOutlineWritingReq) => {
  return (await copilotApi.RemoveOutlineWriting(params)) as Promise<RemoveOutlineWritingResp>;
};

// 分页查询大纲写作的文章列表
export const queryOutlineWriting = async (params: QueryOutlineWritingReq) => {
  return (await copilotApi.QueryOutlineWriting(params)) as Promise<QueryOutlineWritingResp>;
};

// 全文大纲生成
export const advancedWritingOutlineGenerate = async (params: AdvancedWritingOutlineGenerateReq) => {
  return (await copilotApi.AdvancedWritingOutlineGenerate(params)) as Promise<AdvancedWritingOutlineGenerateResp>;
};

// 全文内容生成
export const advancedWritingArticleGenerate = async (params: AdvancedWritingArticleGenerateReq) => {
  return (await copilotApi.AdvancedWritingArticleGenerate(params)) as Promise<AdvancedWritingArticleGenerateResp>;
};

// 章节大纲生成
export const advancedWritingParagraphOutlineGenerate = async (params: AdvancedWritingParagraphOutlineGenerateReq) => {
  return (await copilotApi.AdvancedWritingParagraphOutlineGenerate(params)) as Promise<AdvancedWritingParagraphOutlineGenerateResp>;
};

// 章节内容生成
export const advancedWritingParagraphGenerate = async (params: AdvancedWritingParagraphGenerateReq) => {
  return (await copilotApi.AdvancedWritingParagraphGenerate(params)) as Promise<AdvancedWritingParagraphGenerateResp>;
};

// 手动新增节的版本
export const advancedWritingParagraphContentCreate = async (params: AdvancedWritingParagraphContentCreateReq) => {
  return (await copilotApi.AdvancedWritingParagraphContentCreate(params)) as Promise<AdvancedWritingParagraphContentCreateResp>;
};

// 大纲内容实时保存
export const advancedWritingOutlineSave = async (params: AdvancedWritingOutlineSaveReq) => {
  return (await copilotApi.AdvancedWritingOutlineSave(params)) as Promise<AdvancedWritingOutlineSaveResp>;
};

// 大纲内容查询
export const advancedWritingOutlineQuery = async (params: AdvancedWritingOutlineQueryReq) => {
  return (await copilotApi.AdvancedWritingOutlineQuery(params)) as Promise<AdvancedWritingOutlineQueryResp>;
};

// 大纲写作基本信息查询
export const QueryOutlineWritingInfo = async (params: QueryOutlineWritingInfoReq) => {
  return (await copilotApi.QueryOutlineWritingInfo(params)) as Promise<QueryOutlineWritingInfoResp>;
};

// 更新大纲写作的基本信息
export const EditOutlineWritingInfo = async (params: EditOutlineWritingInfoReq) => {
  return (await copilotApi.EditOutlineWritingInfo(params)) as Promise<EditOutlineWritingInfoResp>;
};

// 关键字生成
export const AdvancedWritingKeywordsGenerate = async (params: AdvancedWritingKeywordsGenerateReq) => {
  return (await copilotApi.AdvancedWritingKeywordsGenerate(params)) as Promise<AdvancedWritingKeywordsGenerateResp>;
};

// 新增章节
export const AdvancedWritingParagraphOutlineCreate = async (params: AdvancedWritingParagraphOutlineCreateReq) => {
  return (await copilotApi.AdvancedWritingParagraphOutlineCreate(params)) as Promise<AdvancedWritingParagraphOutlineCreateResp>;
};

// 删除章节
export const AdvancedWritingParagraphOutlineDelete = async (params: AdvancedWritingParagraphOutlineDeleteReq) => {
  return (await copilotApi.AdvancedWritingParagraphOutlineDelete(params)) as Promise<AdvancedWritingParagraphOutlineDeleteResp>;
};

//  判断用户是否需要操作打分类型生成反馈
export const needScoreFeedback = async (params: NeedScoreFeedbackReq) => {
  return (await copilotApi.NeedScoreFeedback(params)) as Promise<NeedScoreFeedbackResp>;
};
