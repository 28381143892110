import { Navigate, useLocation, useNavigate } from '@modern-js/runtime/router';
import { useContext, useEffect, useState } from 'react';
import { Dropdown, Avatar } from '@arco-design/web-react';

import GlobalStore from '@store/globalStore';
import { useModel } from '@modern-js/runtime/model';
import { useMemoizedFn } from 'ahooks';
import useStorage from '@hooks/useStorage';
// import useLocale from '@hooks/useLocale';
import { GlobalContext } from './context';
import { AUTH_STATUS_KEY, AuthorizationStatus } from '@/config/constant';
import UserDropdown from '@/components/user-dropdown';
import { fetchUserInfo } from '@/api/passport_api';
// import { ErrorCode } from '@/api/errno';
import useUrlParams from '@/hooks/useUrlParams';

export enum PasswordStatus {
  NoChangeNeeded = 'NoChangeNeeded', // 无需修改密码
  NeedsChange = 'NeedsChange', // 需要修改密码
}

export const UserAuthProvider = ({ children }: { children: JSX.Element }) => {
  const { resetToken = '' } = useUrlParams();
  const navigate = useNavigate();
  const { setUserInfo } = useContext(GlobalContext);

  const [, actions] = useModel(GlobalStore);
  //   const [passwordStatus, setPasswordStatus] = useStorage('PASSWORD_STATUS', `${PasswordStatus.NoChangeNeeded}`);
  useEffect(() => {
    if (!(window.location.href.indexOf('/pwd/reset') >= 0) && !resetToken) {
      getUser();
    }
  }, []);

  const getUser = useMemoizedFn(async () => {
    try {
      const { userInfo = {} } = await fetchUserInfo({});
      setUserInfo?.({ userUniqueId: userInfo.accountId, userId: userInfo.accountId });
      actions.setUser(userInfo);
      //   setPasswordStatus(`${PasswordStatus.NoChangeNeeded}`);
      sessionStorage.setItem(AUTH_STATUS_KEY, AuthorizationStatus.Authenticated);
      const isLoginPage = window.location.href.includes('/login');
      const isLoggedIn = userInfo?.accountId;
      if (isLoginPage || isLoggedIn) {
        navigate('/writing', { replace: true });
      }
    } catch (error: any) {
      //   if (error.code === ErrorCode.H_ERR_USER_UNACTIVATE) {
      //     // setPasswordStatus(`${PasswordStatus.NeedsChange}`);
      //   }
    }
  });

  return children;
};

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const hasAuth = sessionStorage.getItem(AUTH_STATUS_KEY);

  if (!hasAuth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export const AuthUserInfo = () => {
  const [{ userInfo = {} }] = useModel(GlobalStore);
  const [visible, setVisible] = useState(false);

  return (
    <>
      {userInfo?.accountId ? (
        <Dropdown
          droplist={<UserDropdown />}
          popupVisible={visible}
          onVisibleChange={visible => {
            setVisible(visible);
          }}
          position="br"
          trigger="click"
        >
          <div className="flex items-center cursor-pointer select-none text-base ">
            <Avatar autoFixFontSize style={{ backgroundColor: '#6585fa' }}>
              {userInfo?.screenName?.substring(0, 1)}
            </Avatar>
          </div>
        </Dropdown>
      ) : (
        <></>
      )}
    </>
  );
};
