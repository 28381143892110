export const writePromptsI18n = {
  'en-US': {
    'ai.writing.expand.prompt.1': 'Give an example',
    'ai.writing.expand.prompt.2': 'Quote famous sayings',
    'ai.writing.expand.prompt.3': 'In-depth argumentation',
    'ai.writing.expand.prompt.value.1': 'Give a specific example to illustrate',
    'ai.writing.expand.prompt.value.2': 'Quote a famous saying to elevate the current content',
    'ai.writing.expand.prompt.value.3': 'Add evidence and expand it into a dialectical paragraph',

    'ai.writing.rewrite.prompt.1': 'More friendly tone',
    'ai.writing.rewrite.prompt.2': 'More formal tone',
    'ai.writing.rewrite.prompt.3': 'More casual tone',
    'ai.writing.rewrite.prompt.4': 'Quote a saying',
    'ai.writing.rewrite.prompt.5': 'In-depth argumentation',
    'ai.writing.rewrite.prompt.value.1': 'More friendly tone, maintaining its original meaning',
    'ai.writing.rewrite.prompt.value.2': 'More formal tone, maintaining its original meaning',
    'ai.writing.rewrite.prompt.value.3': 'Change to a more casual tone, more like an essay',
    'ai.writing.rewrite.prompt.value.4': 'Quote a saying within the content',
    'ai.writing.rewrite.prompt.value.5': 'Add evidence and expand it into a dialectical paragraph',

    'ai.writing.continue.prompt.1': 'In-depth argument',
    'ai.writing.continue.prompt.2': 'Additional factual information',
    'ai.writing.continue.prompt.value.1': 'Give a specific example to illustrate this content, add it after the selected words.',
    'ai.writing.continue.prompt.value.2': '',

    'ai.writing.summary.prompt.1': 'Extract key idea',
    'ai.writing.summary.prompt.2': 'Summarise in outline form',
    'ai.writing.summary.prompt.3': 'Summarise with user prompt',
  },
  'zh-CN': {
    'ai.writing.expand.prompt.1': '举个例子',
    'ai.writing.expand.prompt.2': '引用名言',
    'ai.writing.expand.prompt.3': '深入论证',
    'ai.writing.expand.prompt.value.1': '增加一个具体的例子解释说明这段内容',
    'ai.writing.expand.prompt.value.2': '引用一句名人名言，升华当前内容',
    'ai.writing.expand.prompt.value.3': '增加论据并扩写为一段辩证的内容',

    'ai.writing.rewrite.prompt.1': '语气更友好',
    'ai.writing.rewrite.prompt.2': '语气更正式',
    'ai.writing.rewrite.prompt.3': '语气更随意',
    'ai.writing.rewrite.prompt.4': '引用名言',
    'ai.writing.rewrite.prompt.5': '深入论证',
    'ai.writing.rewrite.prompt.value.1': '在保持语义基本不变的前提下，将语气修改得友好',
    'ai.writing.rewrite.prompt.value.2': '在保持语义基本不变的前提下，将语气修改得更正式',
    'ai.writing.rewrite.prompt.value.3': '语气更随意，更像随笔',
    'ai.writing.rewrite.prompt.value.4': '请在内容中引用名言',
    'ai.writing.rewrite.prompt.value.5': '增加论据并扩写为一段辩证的内容',

    'ai.writing.continue.prompt.1': '深入论证',
    'ai.writing.continue.prompt.2': '补充事实',
    'ai.writing.continue.prompt.value.1': '请结合事例和数据，从多个角度论证此内容',
    'ai.writing.continue.prompt.value.2': '请补充与此内容相关的事例或数据',

    'ai.writing.summary.prompt.1': '提取中心思想',
    'ai.writing.summary.prompt.2': '总结成大纲形式',
    'ai.writing.summary.prompt.3': '根据用户指令总结',
  },
  'zh-HK': {
    'ai.writing.expand.prompt.1': '舉個例子',
    'ai.writing.expand.prompt.2': '引用名言',
    'ai.writing.expand.prompt.3': '深入論證',
    'ai.writing.expand.prompt.value.1': '增加一個具體的例子解釋說明這段內容',
    'ai.writing.expand.prompt.value.2': '引用一句名人名言，昇華當前內容',
    'ai.writing.expand.prompt.value.3': '增加論據並擴寫為一段辯證的內容',

    'ai.writing.rewrite.prompt.1': '語氣更友好',
    'ai.writing.rewrite.prompt.2': '語氣更正式',
    'ai.writing.rewrite.prompt.3': '更隨意',
    'ai.writing.rewrite.prompt.4': '引用名言',
    'ai.writing.rewrite.prompt.5': '深入論證',
    'ai.writing.rewrite.prompt.value.1': '在保持語意基本不變的前提下，將語氣修改得友好',
    'ai.writing.rewrite.prompt.value.2': '在保持語意基本不變的前提下，將語氣修改得更正式',
    'ai.writing.rewrite.prompt.value.3': '語氣更隨意，更像隨筆',
    'ai.writing.rewrite.prompt.value.4': '請在內容中引用名言',
    'ai.writing.rewrite.prompt.value.5': '增加論據並擴寫為一段辯證的內容',

    'ai.writing.continue.prompt.1': '深入論證',
    'ai.writing.continue.prompt.2': '補充事實',
    'ai.writing.continue.prompt.value.1': '請結合事例和數據，從多個角度論證此內容',
    'ai.writing.continue.prompt.value.2': '請補充與此內容相關的例子或數據',

    'ai.writing.summary.prompt.1': '提取中心思想',
    'ai.writing.summary.prompt.2': '總結成大綱形式',
    'ai.writing.summary.prompt.3': '根據用戶指令總結',
  },
};
