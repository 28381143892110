import './index.scss';
import { Modal, Tabs, Input, Upload, Checkbox, Button, Message, Spin } from '@arco-design/web-react';
import type { ModalProps, TabsProps } from '@arco-design/web-react';
import { IconPaperPlaneRightBlue, IconLink, IconAttachment, IconCloseButton } from '@arco-iconbox/react-hkgai-government-icon';
import cls from 'classnames';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { validator } from '@utils/validator';
import { IconPlus } from '@arco-iconbox/react-hkgai-fe-icon';
import { RequestOptions } from '@arco-design/web-react/es/Upload';
import { v4 as uuidv4 } from 'uuid';
import useLocale from '@/hooks/useLocale';
import { getFileExt } from '@/utils/getFileExt';
import { parseFileToTxt } from '@/api/copilot_api';

const CheckboxGroup = Checkbox.Group;

const { TabPane } = Tabs;

enum ActionType {
  UPLOAD_ATTACHMENT = 1,
  ADD_LINK = 2,
}

export enum ResourceType {
  FILE = 1,
  ATTACHMENT = 2,
}

const actionTypeText: { [key in ActionType]: string } = {
  [ActionType.UPLOAD_ATTACHMENT]: 'upload_modal_tab_add_file',
  [ActionType.ADD_LINK]: 'upload_modal_tab_add_links',
};

export interface Resource {
  value?: string;
  uuid: string;
  name?: string;
  type?: ResourceType;
  status?: 'loading' | 'done';
}

interface ResourcesModalProps {
  modalProps: ModalProps;
  extraMessage?: string;
  simple?: boolean;
  tabs?: ActionType[];
  resources?: Resource[];
  defaultValue?: Array<{ id?: string; name?: string; type?: ResourceType }>;
  tabsProps?: TabsProps;
  limitNumber?: number;
  onClear?: () => void;
  onDelete?: () => void;
  onConfirm?: (value: Resource[]) => void;
  onUpload?: (value: Resource[]) => void;
  onChange?: (data: Resource[]) => void;
}

const FILE_ACCEPT = ['.pdf', '.txt', '.docx'];
const maxFileSize = 15 * 1024 * 1024;

const ResourcesModal: FC<ResourcesModalProps> = ({
  modalProps,
  extraMessage,
  tabs = [],
  resources = [],
  defaultValue = [],
  tabsProps = {},
  limitNumber = 10,
  onConfirm,
  onChange,
  onUpload,
}) => {
  const locale = useLocale();
  const textareaRef = useRef<any>(null);
  const [cursorIndex, setCursorIndex] = useState(0);
  const [inputVal, setInputVal] = useState('');
  const [attachmentStatus, setAttachmentStatus] = useState<'error' | 'warning' | undefined>(undefined);

  // console.log('ResourcesModal defaultValue', defaultValue);

  const [fileCheckedValue, setFileCheckedValue] = useState<string[]>(defaultValue.filter(item => item.type === ResourceType.FILE).map(item => item?.id || '') || []);
  const [linkCheckedValue, setLinkCheckedValue] = useState(defaultValue.filter(item => item.type === ResourceType.ATTACHMENT).map(item => item?.id || '') || []);

  const [fileResources, linkResources] = useMemo(() => {
    return [resources.filter(resource => resource.type === ResourceType.FILE), resources.filter(resource => resource.type === ResourceType.ATTACHMENT)];
  }, [resources]);

  const checkValue = useMemo(() => {
    return [...fileCheckedValue, ...linkCheckedValue];
  }, [fileCheckedValue, linkCheckedValue]);

  useEffect(() => {
    if (textareaRef?.current) {
      textareaRef?.current?.setSelectionRange?.(cursorIndex, cursorIndex);
    }
  }, [cursorIndex, textareaRef?.current]);

  const onInputChange = (value: string) => {
    setInputVal(() => value);
  };

  const handleAddLinkSubmit = (value = inputVal) => {
    if (!value) {
      return false;
    }

    // 是否合法
    if (value && !validator.checkUrl(value)) {
      setAttachmentStatus(() => 'error');
      return false;
    }
    // 校验是否已经达到最大标签数
    if (linkResources.length >= limitNumber) {
      Message.error(locale.upload_modal_add_link_max_limit.replace(/{limitNumber}/g, limitNumber));
      return false;
    }

    // 是否重复
    if (linkResources.length && linkResources?.some((item: Resource) => item?.value === value)) {
      setAttachmentStatus(() => 'error');
      return false;
    }

    setAttachmentStatus(() => undefined);
    const linkId = uuidv4();
    onChange?.([...resources, { uuid: linkId, name: inputVal || '', value: inputVal || '', type: ResourceType.ATTACHMENT }]);
    setLinkCheckedValue(() => [linkId]);
    setInputVal('');
    return true;
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  // 定义键盘按下事件的处理函数
  const handleKeyUp = (event: any) => {
    event.preventDefault();
    if (event.key === 'Enter' && event.shiftKey) {
      const textarea = event.target;
      const { selectionStart = 0, selectionEnd = 0 } = textarea;
      setInputVal(() => `${inputVal.slice(0, selectionStart)}${`\n`}${inputVal.slice(selectionEnd)}`);
      setCursorIndex(() => Number(selectionStart) + 1);
    } else if (event.key === 'Enter') {
      handleAddLinkSubmit(inputVal);
    }
  };

  const onInputTagChange = (value: string[], type: ResourceType) => {
    if (value.length > 2) {
      Message.error(
        locale.upload_modal_tab_limit_text
          .replace(/{fileNumber}/, `${fileCheckedValue.length}`)
          .replace(/{linkNumber}/, `${linkCheckedValue.length}`)
          .replace(/{limitNumber}/g, '2'),
      );

      return;
    }
    if (type === ResourceType.ATTACHMENT) {
      setLinkCheckedValue(value);
    }
    if (type === ResourceType.FILE) {
      setFileCheckedValue(value);
    }
  };

  const onUploadRequest = async (requestOptions: RequestOptions) => {
    try {
      const { file } = requestOptions;
      const text = await parseFileToTxt(file);
      console.log('parseFileToTxt value ======>', text, 'resources', resources, 'file ====', file);
      onChange?.(resources.map(item => (item?.name === file.name ? { ...item, value: text, status: 'done' } : item)));
    } catch (error) {
      console.log(error);
    }
  };

  const onBeforeUpload = (file: File, files: File[]) => {
    return new Promise((resolve, reject) => {
      //  exceed limit
      if (fileResources.length >= limitNumber) {
        Message.error(locale.upload_modal_upload_file_max_limit.replace(/{fileNumber}/, limitNumber));
        reject(new Error());
        return;
      }

      //  file size
      if (files.every(item => item.size > maxFileSize)) {
        Message.error(locale.upload_modal_upload_file_limit.replace(/{fileSize}/, maxFileSize / 1024 / 1024).replace(/{ext}/, FILE_ACCEPT.join(', ').replace(/\./g, '')));
        reject(new Error());
        return;
      }

      // accept type
      const fileExt = getFileExt(file?.name);
      if (!FILE_ACCEPT?.includes(fileExt.toLowerCase())) {
        Message.error(locale.upload_modal_upload_file_limit.replace(/{fileSize}/, maxFileSize / 1024 / 1024).replace(/{ext}/, FILE_ACCEPT.join(', ').replace(/\./g, '')));
        reject(new Error());
        return;
      }

      Modal.confirm({
        title: locale['privacy.modal.report.title'],
        // eslint-disable-next-line react/no-danger
        content: <div style={{ width: '400px' }} dangerouslySetInnerHTML={{ __html: locale['privacy.modal.upload.content'] }}></div>,
        okText: locale['privacy.modal.upload.submit'],
        cancelText: locale['privacy.modal.upload.cancel'],
        onConfirm: () => {
          const fileUid = uuidv4();
          onUpload?.(files.map(item => ({ uuid: fileUid, name: item.name || '', status: 'loading', type: ResourceType.FILE })));
          setFileCheckedValue(() => [fileUid]);
          resolve(true);
        },
        // eslint-disable-next-line prefer-promise-reject-errors
        onCancel: () => reject('cancel'),
        getPopupContainer: modalProps?.getPopupContainer || (() => document.body),
      });
    });
  };

  const renderTabPaneContent = (type: ActionType) => {
    const tabPaneMap = {
      [ActionType.UPLOAD_ATTACHMENT]: (
        <div className="resources">
          <div className="resources-content">
            <ResourcesTag
              resourceOptions={fileResources}
              value={fileCheckedValue}
              onChange={value => onInputTagChange(value, ResourceType.FILE)}
              onClose={data => {
                if (data.type === ResourceType.ATTACHMENT) {
                  setLinkCheckedValue(old => old.filter(item => item !== data.uuid));
                }
                if (data.type === ResourceType.FILE) {
                  setFileCheckedValue(old => old.filter(item => item !== data.uuid));
                }
                onChange?.(resources.filter(item => item?.uuid !== data.uuid));
              }}
            />
          </div>
          <div className="resources-upload">
            <Upload drag showUploadList={false} customRequest={onUploadRequest} beforeUpload={onBeforeUpload}>
              <div className="trigger">
                <div>
                  <IconPlus />
                </div>
                <div className="trigger-title">{locale.upload_modal_upload_placeholder}</div>

                <div className="trigger-tips">
                  {locale.upload_modal_upload_trigger_tips
                    ?.replace(/{fileNumber}/, limitNumber)
                    .replace(/{ext}/, FILE_ACCEPT.join(', ').replace(/\./g, ''))
                    .replace(/{fileSize}/, maxFileSize / 1024 / 1024)}
                </div>
              </div>
            </Upload>
          </div>
        </div>
      ),
      [ActionType.ADD_LINK]: (
        <div className="resources">
          <div className="resources-content">
            <ResourcesTag
              resourceOptions={linkResources}
              value={linkCheckedValue}
              onChange={value => onInputTagChange(value, ResourceType.ATTACHMENT)}
              onClose={data => {
                if (data.type === ResourceType.ATTACHMENT) {
                  setLinkCheckedValue(old => old.filter(item => item !== data.uuid));
                }
                if (data.type === ResourceType.FILE) {
                  setFileCheckedValue(old => old.filter(item => item !== data.uuid));
                }
                onChange?.(resources.filter(item => item?.uuid !== data.uuid));
              }}
            />
          </div>
          <div className={`resources-input ${attachmentStatus || ''}`}>
            <IconLink />
            <div className="resources-input-textarea">
              <Input.TextArea
                value={inputVal}
                placeholder={locale.upload_modal_add_links_placeholder?.replace?.(/{limitNumber}/, '10')}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                onChange={onInputChange}
                // onPressEnter={handleAddLinkSubmit}
                status={attachmentStatus}
                onMouseDown={e => {
                  e.currentTarget.focus();
                }}
              />
              <IconPaperPlaneRightBlue onClick={() => handleAddLinkSubmit(inputVal)} />
            </div>
          </div>
        </div>
      ),
    };
    return tabPaneMap[type];
  };

  const extraActionButton = useMemo(() => {
    // console.log(!(fileCheckedValue.length || linkCheckedValue.length), 'disabled');

    return (
      <>
        <Button
          disabled={!(fileCheckedValue.length || linkCheckedValue.length)}
          onClick={() => {
            onConfirm?.(
              checkValue.map(item => {
                const sleVal = resources.find(opt => opt.uuid === item);
                return sleVal?.uuid ? sleVal : item;
              }) as Resource[],
            );
          }}
          type="primary"
          className="extra-action-button confirm-button"
        >
          {locale.upload_modal_tabs_extra_button}
        </Button>
      </>
    );
  }, [fileCheckedValue, linkCheckedValue, resources, onConfirm]);

  return (
    <Modal {...modalProps} footer={null} className="resources-modal">
      <Tabs
        {...tabsProps}
        extra={
          <div className="extra-action">
            <div>
              {extraMessage
                ?.replace(/{fileNumber}/, `${fileCheckedValue.length}`)
                .replace(/{linkNumber}/, `${linkCheckedValue.length}`)
                .replace(/{limitNumber}/g, '2')}
            </div>
            {extraActionButton}
          </div>
        }
      >
        {tabs.map(item => (
          <TabPane key={item} title={locale[actionTypeText[item]]}>
            {renderTabPaneContent(Number(item))}
          </TabPane>
        ))}
      </Tabs>
    </Modal>
  );
};

export default ResourcesModal;
export { ResourcesModalProps, ActionType };

interface ResourcesTagProps {
  resourceOptions: Resource[];
  value?: string[];
  onChange?: (val: string[]) => void;
  onClose?: (value: Resource) => void;
}

const ResourcesTag: FC<ResourcesTagProps> = ({ resourceOptions = [], value = [], onChange, onClose }) => {
  console.log('ResourcesTag====>', resourceOptions);

  return (
    <CheckboxGroup className="resources-input-tag" value={value} onChange={onChange}>
      {resourceOptions.map(item => (
        <Checkbox key={item?.uuid} value={item.uuid}>
          {({ checked }) => {
            return (
              <div className={cls('custom-checkbox-card', { 'custom-checkbox-card-checked': checked })}>
                {item.status === 'loading' ? <Spin style={{ fontSize: '24px' }} /> : <IconAttachment style={{ fontSize: '24px' }} />}
                <div className="custom-checkbox-card-title">{item?.name}</div>
                <div
                  className="custom-checkbox-card-mask"
                  style={{ visibility: checked ? 'visible' : 'hidden' }}
                  onClick={e => {
                    e.stopPropagation();
                    onClose?.(item);
                  }}
                >
                  <IconCloseButton style={{ color: 'rgba(78, 89, 105, 1)' }} />
                </div>
              </div>
            );
          }}
        </Checkbox>
      ))}
    </CheckboxGroup>
  );
};
