import axios from 'axios';
import { UploadItem } from '@arco-design/web-react/es/Upload';
import HttpServer, { ContentType, interceptorRequestConfig, interceptorRequestError, interceptorResponseConfig, interceptorResponseError } from './request';

import type {
  InfoReq,
  InfoResp,
  SensitveVerifyReq,
  SensitveVerifyResp,
  ResultReport,
  GetGlobalNotificationReq,
  GetGlobalNotificationResp,
  SubscribeRequest,
  SubscribeResponse,
  DeleteConversationReq,
  DeleteConversationResp,
} from '@/type/copilot';
import type { ProofreadingCheckReq, ProofreadingCheckResponse } from '@/type/proofread';

const httpServer = new HttpServer('/');

// 配置通用拦截器
httpServer.serviceInstance.interceptors.request.use(interceptorRequestConfig, interceptorRequestError);
httpServer.serviceInstance.interceptors.response.use(interceptorResponseConfig, interceptorResponseError);

/**
 * 查询用户个人信息
 * 描述：获取用户所在部门以及用户角色
 * @param  InfoReq
 * @returns  GET /admin/info
 */
export const getUserInfo = async (params: InfoReq): Promise<InfoResp> => {
  return await httpServer.get('/admin/info', params);
};

/**
 * 用户认证（邮件验证码）
 * POST /admin/sensitive/verify
 * @param params
 * @returns
 */
export const sendVerify = async (params: SensitveVerifyReq): Promise<SensitveVerifyResp> => {
  return await httpServer.post('/admin/sensitive/verify', params);
};

export const chatResultReport = async (params: ResultReport): Promise<void> => {
  return await httpServer.post('/copilot/api/v1/feedback/chat', params);
};

// 获取通知
export const getNotification = async (params: GetGlobalNotificationReq): Promise<GetGlobalNotificationResp> => {
  return await httpServer.get('/copilot/api/v1/notification/get', params);
};

// 获取通知
export const getSubscribe = async (params: SubscribeRequest): Promise<SubscribeResponse> => {
  return await httpServer.get('/copilot/api/v1/sidebar', params);
};

export const parseFileToTxt = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);

  return new Promise((resolve, reject) => {
    axios
      .post('/v1/document/parse', formData, {
        headers: {
          'Content-Type': ContentType.FORM_DATA,
          'X-App-Id': 2,
        },
      })
      .then(response => {
        console.log(response.data);
        if (response.data.data) {
          resolve(response.data.data.text);
        }
        resolve('');
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
};

export const sendGlobalFeedback = async (content: string, images: UploadItem[] = []): Promise<string> => {
  const formData = new FormData();
  formData.append('content', content || '');

  if (images.length) {
    images.forEach(file => {
      formData.append('images', file.originFile as File);
    });
  }

  return new Promise((resolve, reject) => {
    axios
      .post('/copilot/api/v1/feedback/global', formData, {
        headers: {
          'Content-Type': ContentType.FORM_DATA,
          'X-App-Id': 2,
        },
      })
      .then(response => {
        console.log(response.data);
        if (response.data.data) {
          resolve(response.data.data);
        }
        resolve('');
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
};

// 下载文档
export const fetchDownloadFile = async (title: string, data: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .post('/slate-transform/api/docx', { data, type: 'html' }, { headers: { 'Content-Type': 'application/json' }, responseType: 'blob' })
      .then(response => {
        if (response.data) {
          console.log('response=====>', response.data);

          resolve(response.data);
        }
        resolve('');
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchProofreadingCheck = async (params: ProofreadingCheckReq): Promise<ProofreadingCheckResponse> => {
  return await httpServer.post('/proofread/v1/text/check', params);
};

export const fetchDeleteConversation = async (params: DeleteConversationReq): Promise<DeleteConversationResp> => {
  return await httpServer.delete('/v1/conversation', params);
};
