export const shuffleCaseI18n = {
  'zh-CN': {
    'ai-bot-faqs-1': '香港政府有多少个决策局？',
    'ai-bot-faqs-2': '香港政府的哪个决策局负责处理财政和库务相关事务？',
    'ai-bot-faqs-3': '香港政府的教育局下辖哪些部门？',
    'ai-bot-faqs-4': '发展局与其下属部门之间的组织结构是怎样的？',
    'ai-bot-faqs-5': '从行政长官办公室到各个委员会，共有多少层级？',
    'ai-bot-faqs-6': '公务员事务在香港政府的架构中是如何管理的？',
    'ai-bot-faqs-7': '卫生局在医院服务和公共卫生方面的角色是什么？',
    'ai-bot-faqs-8': '保安局的主要职责是什么？',
    'ai-bot-faqs-9': '香港政府的哪个部门负责科技和通讯政策？',
    'ai-bot-faqs-10': '香港政府的三司十五局都是哪些？',
  },
  'zh-HK': {
    'ai-bot-faqs-1': '香港政府有幾個決策局？',
    'ai-bot-faqs-2': '香港政府的哪個決策局負責處理財政和庫務相關事務？',
    'ai-bot-faqs-3': '香港政府的教育局下轄哪些部門？',
    'ai-bot-faqs-4': '發展局與其下屬部門之間的組織架構是怎樣的？',
    'ai-bot-faqs-5': '從行政長官辦公室到各委員會，共有多少層級？',
    'ai-bot-faqs-6': '公務員事務在香港政府的架構中是如何管理的？',
    'ai-bot-faqs-7': '衛生局在醫院服務和公共衛生方面的角色是什麼？',
    'ai-bot-faqs-8': '保安局的主要職責是什麼？',
    'ai-bot-faqs-9': '香港政府的哪個部門負責科技同通訊政策？',
    'ai-bot-faqs-10': '香港政府的三司十五局是哪些？',
  },
  'en-US': {
    'ai-bot-faqs-1': 'How many policy bureaus are there in the Hong Kong government?',
    'ai-bot-faqs-2': 'Which policy bureau of the Hong Kong government is responsible for handling financial and treasury matters?',
    'ai-bot-faqs-3': 'What departments are under the Education Bureau of the Hong Kong government?',
    'ai-bot-faqs-4': 'What is the organizational structure between the Development Bureau and its subordinate departments?',
    'ai-bot-faqs-5': 'How many levels are there from the Chief Executive’s Office to the various committees?',
    'ai-bot-faqs-6': 'How are civil service affairs managed within the Hong Kong government structure?',
    'ai-bot-faqs-7': 'What is the role of the Health Bureau in hospital services and public health?',
    'ai-bot-faqs-8': 'What are the main responsibilities of the Security Bureau?',
    'ai-bot-faqs-9': 'Which department of the Hong Kong government is responsible for technology and communications policy?',
    'ai-bot-faqs-10': 'What are the three departments and fifteen bureaus of the Hong Kong government?',
  },
};
