import {
  ALIGN_KEY,
  BOLD_KEY,
  ITALIC_KEY,
  ORDERED_LIST_KEY,
  STRIKE_THROUGH_KEY,
  STYLE_SETTING_KEY,
  FONT_SETTING_KEY,
  UNDERLINE_KEY,
  UNORDERED_LIST_KEY,
  HEADING_KEY,
  LINE_HEIGHT_CUSTOM_KEY,
} from '@hkgai/slate-plugin/dist/lib/plugins';

export interface MenuItem {
  icon?: React.ReactNode;
  key: string;
  value?: string | number;
  options?: MenuItemOptions;
  iconColor?: string;
  menuWidth?: number;
}

export interface MenuItemOptions {
  commonConfig?: MenuItem[];
  fontSizeMap?: MenuItem[];
  colorMap?: MenuItem[];
  backgroundMap?: MenuItem[];
  reset?: MenuItem;
}
export const TOOL_MENU_RESET = 'reset';
export const TOOL_MENU_GROUP_DOS = 'toolMenuGroupDos';
export const TOOL_MENU_GROUP_ACTIONS = 'toolMenuGroupActions';

export enum CustomEventOptions {
  DELETE = 'delete',
  COPY = 'copy',
  DOWNLOAD = 'download',
}

export enum DoActions {
  UNDO = 'undo',
  REDO = 'redo',
}

export interface SelectedMarksProperty {
  [key: string]: string;
}

export interface SelectedMarks {
  [UNORDERED_LIST_KEY]?: boolean;
  [HEADING_KEY]?: SelectedMarksProperty;
  [ALIGN_KEY]?: string;
  [BOLD_KEY]?: boolean;
  [STRIKE_THROUGH_KEY]?: boolean;
  [ITALIC_KEY]?: boolean;
  [UNDERLINE_KEY]?: boolean;
  [ORDERED_LIST_KEY]?: boolean;
  [FONT_SETTING_KEY]?: SelectedMarksProperty;
  [STYLE_SETTING_KEY]?: SelectedMarksProperty;
  [key: string]: any;
}

export type SelectedMarksKeys = keyof SelectedMarks;

export const CUSTOM_EVENT_OPTIONS = ['delete', 'copy', 'download'];

export const BLOCK_LIST = [
  UNORDERED_LIST_KEY,
  HEADING_KEY,
  ALIGN_KEY,
  BOLD_KEY,
  STRIKE_THROUGH_KEY,
  ITALIC_KEY,
  UNDERLINE_KEY,
  ORDERED_LIST_KEY,
  FONT_SETTING_KEY,
  STYLE_SETTING_KEY,
  LINE_HEIGHT_CUSTOM_KEY,
];
