/// 三语文档链接 https://hkgpt-application.sg.larksuite.com/wiki/HxQ3w8mqhiW1zNk6Xfel6aFTg2g
export const adWritingI18n = {
  'en-US': {
    ad_writing: 'Advanced Writing',
    ad_writing_empty: 'Fill in article details on the right',
    ad_writing_article_default_title: 'Untitled',
    ad_writing_general: 'Requirements',
    ad_writing_reference: 'Reference',
    ad_writing_reference_placeholder: 'Please enter reference content',
    ad_writing_outline: 'Outline',
    ad_writing_requirements: 'Writing requirements',
    ad_writing_requirements_placeholder: `Please provide clear writing requirements, such as "Please generate a work summary about the recent developments in Hong Kong's cultural and tourism sectors"`,
    ad_writing_keywords: 'Keywords',
    ad_writing_keywords_placeholder: 'Please enter keywords',
    ad_writing_keywords_limit: 'The selected keywords should not exceed {limit}',
    ad_writing_generate_with_ai: 'Generate with Al',
    ad_writing_next: 'Next',
    ad_writing_back: 'Back',
    ad_writing_upload_file: 'Upload file',
    ad_writing_add_link: 'Add link',
    ad_writing_copy_entire_text: 'Copy entire text',
    ad_writing_word_count: 'Word Count',
    ad_writing_copy: 'Copy',
    ad_writing_outline_regeneration: 'Outline regeneration',
    ad_writing_full_text_generation: 'Full text generation',
    ad_writing_add_summary: 'Add a summary',
    ad_writing_generate: 'Generate',
    ad_writing_add_subsection: 'Add subsection',
    ad_writing_generate_section: 'Generate section',
    ad_writing_add_section: 'Add section',
    ad_writing_insert: 'Insert',
    ad_writing_regenerate: 'Regenerate',
    ad_writing_regenerate_outline_content: 'Regenerating may overwrite all existing outlines and written content',
    ad_writing_subsection_content: 'Regenerating will replace all existing written content within this section',
    ad_writing_all_existing_content: 'Regenerating will replace all existing written content',
    ad_writing_all_existing_section: 'Regenerating will replace all existing written content within this section',
    ad_writing_cancel: 'Cancel',
    ad_writing_confirm: 'Confirm',
    ad_writing_remove: 'Remove',
    ad_writing_regenerate_summary: 'Regenerate summary',
    ad_writing_remove_summary: 'Regenerate summary',
    ad_writing_regenerate_subsection: 'Regenerate section',
    ad_writing_confirm_delete_title: 'Confirm delete?',
    ad_writing_confirm_delete_content: 'Contents cannot be recovered',
    ad_writing_complete_content: 'Please generate content according to the outline',
    ad_writing_keyword_limit: 'The length of the keyword should not exceed {limit}',
    ad_writing_section_title_limit: 'The length of the section/subsection title should not exceed {limit}',
    ad_writing_online_search: 'Online search',
    ad_writing_section_title_placeholder: 'Please enter here',
    ad_writing_generate_outline_error: 'generate outline error',
    ad_writing_generate_section_error: 'generate section/subsection error',
    ad_writing_generate_summary_error: 'generate summary error',
    ad_writing_writing_requirements_limit: 'The length of the writing requirements title should not exceed {limit} charactors',
    ad_writing_generate_section_error_with_title: 'generate subsection: {sectionTitle} error',
    ad_writing_generating_notification: 'Please wait for the generation process to be complete before you switch tabs',
  },
  'zh-CN': {
    ad_writing: '大纲写作',
    ad_writing_empty: '在右侧填写文章详细信息',
    ad_writing_article_default_title: '未命名',
    ad_writing_general: '写作要求',
    ad_writing_reference: '参考',
    ad_writing_reference_placeholder: '请输入参考内容',
    ad_writing_outline: '大纲',
    ad_writing_requirements: '写作要求',
    ad_writing_requirements_placeholder: `请输入明确的写作需求，比如“请生成一篇近年本港文化与旅游领域的工作总结”`,
    ad_writing_keywords: '关键词',
    ad_writing_keywords_placeholder: '请输入关键词',
    ad_writing_keywords_limit: '关键字最多可以选择{limit}个',
    ad_writing_generate_with_ai: 'AI生成',
    ad_writing_next: '下一步',
    ad_writing_back: '上一步',
    ad_writing_upload_file: '上传文件',
    ad_writing_add_link: '添加链接',
    ad_writing_copy_entire_text: '复制全文',
    ad_writing_word_count: '总字数',
    ad_writing_copy: '复制',
    ad_writing_outline_regeneration: '重新生成大纲',
    ad_writing_full_text_generation: '生成全文',
    ad_writing_add_summary: '添加概要',
    ad_writing_generate: '生成',
    ad_writing_add_subsection: '添加小节',
    ad_writing_generate_section: '生成',
    ad_writing_add_section: '添加章节',
    ad_writing_insert: '插入',
    ad_writing_regenerate: '重新生成',
    ad_writing_regenerate_outline_content: '重新生成大纲会覆盖已有大纲内容和文章内容',
    ad_writing_subsection_content: '重新生成会替换当前小节内容和文章内容',
    ad_writing_all_existing_content: '重新生成会替换当前已有写作内容',
    ad_writing_all_existing_section: '重新生成会替换当前小节内容和文章内容',
    ad_writing_cancel: '取消',
    ad_writing_confirm: '确认',
    ad_writing_remove: '移除',
    ad_writing_regenerate_summary: '重新生成概要',
    ad_writing_regenerate_subsection: '重新生成小节',
    ad_writing_remove_summary: '移除概要',
    ad_writing_confirm_delete_title: '确定删除？',
    ad_writing_confirm_delete_content: '删除后将无法找回',
    ad_writing_complete_content: '请根据大纲生成完所有内容',
    ad_writing_keyword_limit: '关键字长度不得超过{limit}',
    ad_writing_section_title_limit: '章/节的标题长度不得超过{limit}字',
    ad_writing_online_search: '联网搜索',
    ad_writing_section_title_placeholder: '请在此输入内容',
    ad_writing_generate_outline_error: '生成大纲失败',
    ad_writing_generate_section_error: '生成章/节失败',
    ad_writing_generate_summary_error: '生成概要失败',
    ad_writing_writing_requirements_limit: '写作要求不得超过{limit}字',
    ad_writing_generate_section_error_with_title: '生成节：{sectionTitle}失败',
    ad_writing_generating_notification: '请生成完成后，再切换文章',
  },
  'zh-HK': {
    ad_writing: '大綱寫作',
    ad_writing_empty: '在右側填寫文章詳細信息',
    ad_writing_article_default_title: '未命名',
    ad_writing_general: '寫作要求',
    ad_writing_reference: '參考',
    ad_writing_reference_placeholder: '請輸入參考內容',
    ad_writing_outline: '大綱',
    ad_writing_requirements: '寫作要求',
    ad_writing_requirements_placeholder: `請輸入明確的寫作需求，例如“請生成近年來本港文化與旅遊領域的工作總結”`,
    ad_writing_keywords: '關鍵字',
    ad_writing_keywords_placeholder: '請輸入關鍵字',
    ad_writing_keywords_limit: '關鍵字最多可以選擇{limit}个',
    ad_writing_generate_with_ai: 'AI生成',
    ad_writing_next: '下一步',
    ad_writing_back: '上一步',
    ad_writing_upload_file: '上傳文件',
    ad_writing_add_link: '添加連結',
    ad_writing_copy_entire_text: '複製全文',
    ad_writing_word_count: '總字數',
    ad_writing_copy: '複製',
    ad_writing_outline_regeneration: '重新生成大綱',
    ad_writing_full_text_generation: '生成全文',
    ad_writing_add_summary: '新增概要',
    ad_writing_generate: '生成',
    ad_writing_add_subsection: '添加小節',
    ad_writing_generate_section: '生成',
    ad_writing_add_section: '添加章節',
    ad_writing_insert: '插入',
    ad_writing_regenerate: '重新生成',
    ad_writing_regenerate_outline_content: '重新生成大綱會覆蓋已有大綱內容和文章內容',
    ad_writing_subsection_content: '重新生成會取代目前小節內容和文章內容',
    ad_writing_all_existing_content: '重新生成會取代目前已有寫作內容',
    ad_writing_all_existing_section: '重新生成會取代目前小節內容和文章內容',
    ad_writing_cancel: '取消',
    ad_writing_confirm: '確認',
    ad_writing_remove: '移除',
    ad_writing_regenerate_summary: '重新生成概要',
    ad_writing_regenerate_subsection: '重新生成小節',
    ad_writing_remove_summary: '移除概要',
    ad_writing_confirm_delete_title: '確定刪除？',
    ad_writing_confirm_delete_content: '刪除後將無法找回',
    ad_writing_complete_content: '請根據大綱生成所有內容',
    ad_writing_keyword_limit: '關鍵字長度不得超過{limit}',
    ad_writing_section_title_limit: '章/節的標題長度不得超過{limit}',
    ad_writing_online_search: '聯網搜索',
    ad_writing_section_title_placeholder: '請在此輸入內容',
    ad_writing_generate_outline_error: '生成大綱失敗',
    ad_writing_generate_section_error: '生成章/節失敗',
    ad_writing_generate_summary_error: '生成概要失敗',
    ad_writing_writing_requirements_limit: '寫作要求不得超過{limit}字',
    ad_writing_generate_section_error_with_title: '生成節：{sectionTitle}失敗',
    ad_writing_generating_notification: '請生成完成後，再切換文章',
  },
};
